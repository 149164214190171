import React, { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@mui/icons-material';
import AvatarsInvolved from './../../AvatarsInvolved';
import {
  getAdvisorRecurlyPlan,
  getCompanyPreviewPosts,
  getCompanyRecentAdvisors,
} from './../../../services/advisor_services';
import PostAndAnswer from './../../Post/Answer';
import BoardJoinButton from './../../BoardJoinButton';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import { useAccount } from './../../../contexts/Account';
import { getCompanyBySlug } from './../../../services/company_services';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topContainer: {
    color: '#232B35',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: '#F5F7FA',
    borderRadius: '0 105px 0 0',
    border: '0px solid black',
    width: '100%',
    padding: '50px 50px 120px 50px',
  },
  postsContainer: {
    width: '100%',
    pointerEvents: 'none',
    backgroundColor: '#FBFCFD',
  },
  postContainer: {
    width: '100%',
    border: '1px solid rgb(230,237,254)',
    borderRadius: '5px',
    marginBottom: '30px',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(251, 252, 253, 0.9)',
    backdropFilter: 'blur(5px)',
    width: '100%',
    textAlign: 'center',
    color: 'white',
  },
  title: {
    display: 'inline',
    fontSize: '20px',
    color: '#232B35',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '16px',
    color: '#646D7A',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '16px',
    color: '#646D7A',
  },
  subtitleContainer: {
    maxWidth: '550px',
  },
  bottomSubtitleContainer: {
    maxWidth: '320px',
  },
  newMembersText: {
    fontSize: '14px',
    color: '#232B35',
    fontWeight: 400,
  },
  membersContainer: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '0px',
    paddingBottom: '0px',
    width: '100%',
  },
};

function CompanyBoardPreview(props) {
  const { company, companyId, companyName, companyCEO } = props;

  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [isLoadingAdvisors, setIsLoadingAdvisors] = useState(true);
  const [companyFreemium, setCompanyFreemium] = useState(false);
  const [postList, setPostList] = useState([]);
  const [advisorAvatar, setAdvisorAvatar] = useState([]);

  const [isJoined, setIsJoined] = useState(
    accountDetails.boards.memberBoards.some((bc) => bc.id === companyId)
  );

  useEffect(() => {
    async function getPosts() {
      setIsLoadingPosts(true);
      getCompanyPreviewPosts({ COMPANY_ID: companyId }).then((response) => {
        setIsLoadingPosts(false);
        if (
          response.data &&
          response.data.getCompanyPreviewPosts &&
          response.data.getCompanyPreviewPosts.posts &&
          response.data.getCompanyPreviewPosts.posts.length
        ) {
          const newPosts = [];
          response.data.getCompanyPreviewPosts.posts.forEach((post) => {
            const temp = post;
            if (
              response.data.getCompanyPreviewPosts.responses &&
              response.data.getCompanyPreviewPosts.responses.length
            ) {
              temp.responses =
                response.data.getCompanyPreviewPosts.responses.filter(
                  (res) => res.postId === post.id
                );
            }
            newPosts.push(temp);
          });
          setPostList(newPosts);
        }
      });
    }

    async function getAdvisors() {
      setIsLoadingAdvisors(true);
      getCompanyRecentAdvisors({ COMPANY_ID: companyId }).then((response) => {
        setIsLoadingAdvisors(false);
        const tempAdvisors = response.data.getCompanyRecentAdvisors;
        let avatarsArr = [];
        if (tempAdvisors && tempAdvisors.length && tempAdvisors.length > 0) {
          avatarsArr = tempAdvisors.map((advisor) => {
            if (advisor.image && advisor.image.imageKey) {
              return `${process.env.REACT_APP_IMAGES_URL}${advisor.image.imageKey}`;
            }
            return null;
          });
        }
        setAdvisorAvatar(avatarsArr);
      });
    }
    getCompanyBySlug({ slug: company.slug }).then(({ data }) => {
      if (data.getCompany) {
        try {
          async function fetchData() {
            const planResponses = await getAdvisorRecurlyPlan({
              recurlyPlanCode: data.getCompany.recurlyPlanCode,
            });
            if (
              planResponses &&
              planResponses.data &&
              planResponses.data.getRecurlyPlan.isFreemium &&
              planResponses.data.getRecurlyPlan.isFreemium === true
            ) {
              setCompanyFreemium(true);
            }
          }
          fetchData();
        } catch (error) {
          console.log('error:', error);
        }
      }
    });

    getPosts();
    getAdvisors();

    setIsJoined(
      accountDetails.boards.memberBoards.some((bc) => bc.id === companyId)
    );
  }, [companyId]);

  function doNothing() {
    return null;
  }

  function isMounted(id) {
    return new Promise(function (resolve, reject) {
      const res = {
        data: {
          getResponses: [],
        },
      };
      const tempPost = postList.find((post) => post.id === id);
      let tempResponses = tempPost.responses;
      tempResponses = tempResponses.sort((a, b) => {
        if (Number(a.likesCount) < Number(b.likesCount)) return 1;
        if (Number(a.likesCount) > Number(b.likesCount)) return -1;
        return 0;
      });
      res.data.getResponses = tempResponses.slice(0, 1);
      resolve(res);
    });
  }

  function newestBoardMembersContainer() {
    if (advisorAvatar && advisorAvatar.length && advisorAvatar.length > 1) {
      if (isSM) {
        return (
          <div className="newestBoardMembers" style={classes.membersContainer}>
            <div>
              <Typography
                style={{ whiteSpace: 'nowrap', fontSize: 16, fontWeight: 200 }}
              >
                {t('NEWEST-BOARD-MEMBERS')}
              </Typography>
            </div>
            <div style={{ marginTop: '10px' }}>
              <AvatarsInvolved
                noText
                avatars={advisorAvatar}
                avatarSize={'md'}
              />
            </div>
          </div>
        );
      }
      return (
        <div className="newestBoardMembers" style={classes.container}>
          <div>
            <Typography
              style={{ whiteSpace: 'nowrap' }}
              sx={classes.newMembersText}
            >
              {t('NEWEST-BOARD-MEMBERS')}
            </Typography>
          </div>
          <div style={{ marginTop: '10px' }}>
            <AvatarsInvolved noText avatars={advisorAvatar} avatarSize={'md'} />
          </div>
        </div>
      );
    }
    return null;
  }

  if (isLoadingPosts || isLoadingAdvisors)
    return (
      <div
        style={{
          paddingTop: '10%',
          paddingBottom: '10%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicatorLogo size={150} iconFontSize={65} iconRight={77} />
      </div>
    );

  return (
    <div style={classes.container}>
      <div
        style={{
          ...classes.topContainer,
          flexDirection: isSM ? 'column' : 'row',
        }}
      >
        <div style={{ paddingRight: 5 }} className="peekInsideBlock">
          <div>
            <Typography sx={classes.title}>
              {companyFreemium
                ? t('PEEK-BOARDROOM-TITLE-FREEMIUM')
                : t('PEEK-BOARDROOM-TITLE')}
            </Typography>
          </div>
          <div style={classes.subtitleContainer}>
            <Typography style={classes.subtitle}>
              {companyFreemium
                ? t('PEEK-BOARDROOM-TEXT-FREEMIUM', {
                    companyName,
                  })
                : t('PEEK-BOARDROOM-TEXT', {
                    companyName,
                  })}
            </Typography>
          </div>
        </div>
        {newestBoardMembersContainer()}
      </div>
      <div
        style={{
          ...classes.postsContainer,
          padding: isSM ? '0 10px 0 10px' : '0 50px 0 50px',
        }}
      >
        {postList.map((post) => {
          return (
            <div
              key={post.id}
              style={{
                ...classes.postContainer,
                transform: isSM ? 'translateY(-85px)' : 'translateY(-70px)',
              }}
            >
              <PostAndAnswer
                postData={post}
                id={post.id}
                isLoaded={(id) => isMounted(id)}
                dashboardFormat
                // DO NOTHING
                postLikesIconClick={doNothing}
                postAnswersIconClick={doNothing}
                postBodyClick={doNothing}
                postCommentsIconClick={doNothing}
                editClick={doNothing}
                answerCommentsIconClick={doNothing}
                answerBodyClick={doNothing}
                // DISABLE
                removeResponseActions
                removeResponse
                postLikesIconDisabled
                postAnswersIconDisabled
                postCommentsIconDisabled
                answerCommentsIconDisabled
                contributorsDisabled
                isOpportunityView
              />
            </div>
          );
        })}
      </div>
      {!isJoined && (
        <div
          style={{
            ...classes.bottomContainer,
            marginBottom: isSM ? '150px' : '0px',
          }}
        >
          <div
            style={{
              transform: 'translateY(-75px)',
              width: 75,
              height: 75,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: 'black',
            }}
          >
            <LockOutlined
              style={{ fontSize: 45, marginTop: 15 }}
              color="black"
            />
          </div>
          <div style={{ transform: 'translateY(-60px)' }}>
            <Typography sx={classes.title}>
              {t('PREVIEW-JOIN-BOARD-TITLE')}
            </Typography>
            {!isSM ? (
              <div
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <BoardJoinButton
                  company={company}
                  boardId={companyId}
                  companyCEO={companyCEO}
                  staticClass="joinButtonBottom"
                />
                <div style={classes.bottomSubtitleContainer}>
                  <Typography sx={{ ...classes.subtitle2, marginBottom: 15 }}>
                    {t('PREVIEW-JOIN-BOARD-TEXT-V2', {
                      companyName,
                    })}
                  </Typography>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyBoardPreview;
