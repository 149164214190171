import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Chip, Icon } from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

const useStyles = (prop) => ({
  itemChipContainer: {
    // width: '100%',
  },
  itemChip: {
    marginRight: prop.edit ? '12px' : '20px',
    marginTop: '7px',
    marginBottom: '7px',
    height: '33px',
    backgroundColor: '#F5F7FA',
    color: 'black',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '24px',
  },
  starIconEmpty: {
    fontSize: '15px',
    marginLeft: '10px',
    color: '#C9C9DA',
    display: 'flex',
    alignItems: 'center',
  },
  starIconFilled: {
    fontSize: '15px',
    color: '#FFC400',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
});

function ChipList(props) {
  const {
    items,
    edit,
    column,
    handleStar,
    canBeStar,
    starList,
    handleDelete,
    maxItems,
    maxRows,
  } = props;
  const classes = useStyles({ edit });
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [contentHeight, setContentHeight] = useState('auto');
  const [containerHeight, setContainerHeight] = useState(0);
  const chipListRef = useRef(null);

  const componentMaxItems = maxItems ? maxItems : 1000;

  const getIsStar = (skillId) => {
    if (starList && starList.length) return starList.includes(skillId);
    return false;
  };

  const sorting = (a, b) => {
    if (canBeStar) {
      if (!getIsStar(a.id) && getIsStar(b.id)) return 1;
      if (getIsStar(a.id) && !getIsStar(b.id)) return -1;
    }
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (chipListRef.current) {
        const isOverflowing =
          chipListRef.current.scrollHeight > chipListRef.current.clientHeight;
        setIsOverflowing(isOverflowing);
        setContentHeight(chipListRef.current.scrollHeight);
        setContainerHeight(maxRows * 47);
      }
    };
    setShowMore(false);
    checkOverflow();

    const handleResize = () => {
      checkOverflow();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items, maxRows]);

  return (
    <div>
      <Box
        ref={chipListRef}
        id="chip-list"
        mt={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          maxHeight: showMore ? `${contentHeight}px` : `${containerHeight}px`,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease-in-out',
          flexDirection: column ? 'column' : 'row',
        }}
      >
        {items
          .sort(sorting)
          .slice(0, componentMaxItems)
          .map((exp) => (
            <div style={classes.itemChipContainer} key={exp.id}>
              <Chip
                label={<span dangerouslySetInnerHTML={{ __html: exp.label }} />}
                sx={{
                  ...classes.itemChip,
                  backgroundColor: !exp.isHighlighted ? '#F5F7FA' : '#FEF8D1',
                }}
                onDelete={edit ? () => handleDelete(exp) : null}
                icon={
                  canBeStar && (getIsStar(exp.id) || edit) ? (
                    <Icon
                      onClick={() => {
                        if (edit) handleStar(exp.id, getIsStar(exp.id));
                      }}
                      iconname={getIsStar(exp.id) ? 'star' : 'star-outlined'}
                      style={{
                        ...(getIsStar(exp.id)
                          ? classes.starIconFilled
                          : classes.starIconEmpty),
                        cursor: edit ? 'pointer' : 'default',
                        overflow: 'visible',
                      }}
                      className="ac-icon-star"
                    />
                  ) : null
                }
              />
            </div>
          ))}
      </Box>

      {isOverflowing && (
        <Box
          id="show-more"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#E6EDFF',
              height: '1px',
              width: '100%',
            }}
          />
          <Typography
            onClick={() => setShowMore(!showMore)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '20px',
              marginRight: '20px',
              color: '#74879A',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            {showMore ? 'Less' : 'More'}
            <ExpandCircleDownOutlinedIcon
              sx={{
                transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform .5s',
                marginLeft: '5px',
              }}
            />
          </Typography>
          <Box
            sx={{
              backgroundColor: '#E6EDFF',
              height: '1px',
              width: '100%',
            }}
          />
        </Box>
      )}
    </div>
  );
}

export default ChipList;
