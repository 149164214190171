import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  CircularProgress,
  Typography,
  Paper,
} from '@mui/material';
import ACSnackbar from './../../ACSnackbar';
import ACAvatar from './../../ACAvatar';
import ACButton from './../../ACButton';
import { getAdvisorImagePath } from './../../../services/utils';
import { SnackbarType } from './../../../services/utils/types';

const useStyles = (isSM) => ({
  mask: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    border: 'none',
  },
  container: {
    padding: '25px',
    display: 'flex',
    width: 'calc(100% - 10px)',
    maxWidth: '650px',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalContainer: {
    textAlign: 'center',
    paddingTop: '0px',
    marginBottom: '10px',
    padding: isSM ? '15px' : '25px',
    borderRadius: '10px',
  },
  fillWidth: {
    width: '100%',
  },
  title: {
    marginBottom: '25px',
    color: 'info.main',
    textAlign: 'left',
  },
  alignRight: {
    marginLeft: 'auto',
    display: 'flex',
  },
  alignLeft: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    color: 'info.light',
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  cancelButtonContainer: {
    marginRight: '8px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
  },
  inviteButtonContainer: {
    marginTop: 'auto',
    height: '35px',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  advisorAvatarContainer: {
    marginRight: '20px',
  },
  advisorName: {
    color: 'info.main',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  advisorInfo: {
    color: 'info.light',
    textAlign: 'left',
  },
  flexContainerVertical: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  advisorDetailsContainer: {
    marginBottom: '10px',
  },
  modalLoadingContainer: {
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
});

function InviteToJoinConfirmationModal(props) {
  const {
    isVisible,
    advisor,
    onConfirm,
    onClose,
    isLoading,
    snackMessage,
    snackbarSuccess,
    setSnackbarSuccess,
    snackbarWarning,
    setSnackbarWarning,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={classes.mask}>
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => setSnackbarSuccess(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={snackbarWarning}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.WARNING}
        onClose={() => setSnackbarWarning(false)}
        autoHideDuration={6000}
      />
      <div style={classes.container}>
        <Paper sx={classes.modalContainer}>
          <Typography
            sx={classes.title}
            variant={isSM ? 'subtitle2_old_font' : 'subtitle1_old_font'}
          >
            {t('INVITE_TO_JOIN_CONFIRMATION', {
              advisorName: advisor.givenName,
            })}
          </Typography>
          <div style={classes.flexContainer}>
            <div
              style={{
                ...classes.alignLeft,
                ...classes.advisorDetailsContainer,
              }}
            >
              <div style={classes.advisorAvatarContainer}>
                <ACAvatar
                  style={classes.advisorAvatar}
                  image={getAdvisorImagePath(advisor)}
                  size={isSM ? 'md' : 'lg-mobile'}
                />
              </div>
              <div style={classes.flexContainerVertical}>
                <Typography variant="caption_old_font" sx={classes.advisorName}>
                  {advisor.displayName}
                </Typography>
                {advisor.title && (
                  <Typography
                    variant="caption_old_font"
                    sx={classes.advisorInfo}
                  >
                    {advisor.title.length > 18
                      ? advisor.title.substring(0, 18) + '...'
                      : advisor.title}
                  </Typography>
                )}
              </div>
            </div>
            <div style={classes.alignRight}>
              {isLoading ? (
                <div style={classes.modalLoadingContainer}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div style={classes.cancelButtonContainer}>
                    <ACButton
                      // color="secondary"
                      variant="outlined"
                      width={isSM ? '64px' : '80px'}
                      style={{
                        height: '30px',
                        paddingTop: 15,
                        paddingBottom: 15,
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: 15,
                        height: 40,
                        borderRadius: 30,
                        backgroundColor: '#FFF',
                        border: '2px solid #0F78FD',
                        color: '#0F78FD',
                        borderColor: '#0F78FD !important',
                      }}
                      onClick={() => onClose()}
                    >
                      <Typography
                        variant={isSM ? 'caption_old_font' : 'body2_old_font'}
                      >
                        {t('CANCEL')}
                      </Typography>
                    </ACButton>
                  </div>
                  <div style={classes.inviteButtonContainer}>
                    <ACButton
                      color="primary"
                      width={isSM ? '64px' : '80px'}
                      style={{
                        height: '30px',
                        backgroundColor: '#0F78FD',
                        paddingTop: 15,
                        paddingBottom: 15,
                        textTransform: 'none',
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        fontSize: 15,
                        height: 40,
                        borderRadius: 30,
                      }}
                      onClick={() => onConfirm()}
                    >
                      <Typography
                        variant={isSM ? 'caption_old_font' : 'body2_old_font'}
                      >
                        {t('INVITE')}
                      </Typography>
                    </ACButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default InviteToJoinConfirmationModal;
