import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Dialog, Slide } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { useAccount } from './../../../contexts/Account';
import {
  getAdvisorPlanUpgrades,
  getAdvisorRecurlyPlan,
} from './../../../services/advisor_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import Step1 from './Step1';
import Step2 from './Step2';

const AdvisorFreemiumModal = ({ onUpgradeSuccess }) => {
  const { accountDetails } = useAccount();
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [openModal, setOpenModal] = useState(false);
  const [step1, setStep1] = useState(true);
  const [monthlyUpgrade1, setMonthlyUpgrade1] = useState('');
  const [monthlyUpgrade2, setMonthlyUpgrade2] = useState('');
  const [monthlyUpgrade3, setMonthlyUpgrade3] = useState('');
  const [annualUpgrade1, setAnnualUpgrade1] = useState('');
  const [annualUpgrade2, setAnnualUpgrade2] = useState('');
  const [annualUpgrade3, setAnnualUpgrade3] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [planData, setPlanData] = useState({});

  const handleContinue = () => {
    setStep1(false);
  };
  const handleBack = () => {
    setStep1(true);
  };

  useEffect(() => {
    async function fetchData() {
      const planResponses = await getAdvisorRecurlyPlan({
        recurlyPlanCode: accountDetails.recurlyPlanCode,
      });

      const {
        b2cFreemiumAnnualUpgrade1,
        b2cFreemiumAnnualUpgrade2,
        b2cFreemiumAnnualUpgrade3,
        b2cFreemiumMonthlyUpgrade1,
        b2cFreemiumMonthlyUpgrade2,
        b2cFreemiumMonthlyUpgrade3,
      } = planResponses.data.getRecurlyPlan;

      const planResponsesMonth1 = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: b2cFreemiumMonthlyUpgrade1,
      });
      const planResponsesMonth2 = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: b2cFreemiumMonthlyUpgrade2,
      });
      const planResponsesMonth3 = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: b2cFreemiumMonthlyUpgrade3,
      });
      const planResponsesAnnual1 = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: b2cFreemiumAnnualUpgrade1,
      });
      const planResponsesAnnual2 = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: b2cFreemiumAnnualUpgrade2,
      });
      const planResponsesAnnual3 = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: b2cFreemiumAnnualUpgrade3,
      });

      setMonthlyUpgrade1(planResponsesMonth1.data.getFreeTrialUpgrades[0]);
      setMonthlyUpgrade2(planResponsesMonth2.data.getFreeTrialUpgrades[0]);
      setMonthlyUpgrade3(planResponsesMonth3.data.getFreeTrialUpgrades[0]);
      setAnnualUpgrade1(planResponsesAnnual1.data.getFreeTrialUpgrades[0]);
      setAnnualUpgrade2(planResponsesAnnual2.data.getFreeTrialUpgrades[0]);
      setAnnualUpgrade3(planResponsesAnnual3.data.getFreeTrialUpgrades[0]);
    }

    eventBus.on(eventBusValues.triggerAdvisorUpgradeAction, () => {
      setOpenModal(true);
    });

    fetchData();
    return () => {
      eventBus.remove(eventBusValues.triggerAdvisorUpgradeAction);
    };
  }, []);

  function handleClose() {
    setOpenModal(false);
    setStep1(true);
  }

  const handleOptionChange = (plan, option, data, counterpartData) => {
    setPlanData({ plan, option, data, counterpartData });
  };

  const handlePlanToggle = (selectedPlan) => {
    setPlanData((currentPlanData) => {
      if (selectedPlan === 'annual' && currentPlanData.plan === 'month') {
        return {
          ...currentPlanData,
          plan: 'annual',
          data: currentPlanData.counterpartData,
          counterpartData: currentPlanData.data,
        };
      } else if (
        selectedPlan === 'month' &&
        currentPlanData.plan === 'annual'
      ) {
        return {
          ...currentPlanData,
          plan: 'month',
          data: currentPlanData.counterpartData,
          counterpartData: currentPlanData.data,
        };
      }
      return currentPlanData;
    });
  };

  return (
    <div>
      <Dialog
        open={openModal}
        fullScreen={isSM}
        onClose={handleClose}
        PaperProps={{
          id: 'advisorFreemiumUpgradeModal',
          sx: {
            borderRadius: isSM ? '0px' : '15px',
            boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
            height: { xxs: '100%', sm: '600px' },
            width: { xxs: '100%', sm: '920px' },
          },
        }}
      >
        {isSM && (
          <div
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
          >
            <Clear onClick={handleClose} />
          </div>
        )}

        {isFirstLoad ? (
          <div>
            <Step1
              onContinue={() => {
                handleContinue();
                setIsFirstLoad(false);
              }}
              month1={monthlyUpgrade1}
              month2={monthlyUpgrade2}
              month3={monthlyUpgrade3}
              annual1={annualUpgrade1}
              annual2={annualUpgrade2}
              annual3={annualUpgrade3}
              onOptionChange={handleOptionChange}
            />
          </div>
        ) : (
          <Slide direction="right" in={step1} mountOnEnter unmountOnExit>
            <div>
              <Step1
                onContinue={handleContinue}
                month1={monthlyUpgrade1}
                month2={monthlyUpgrade2}
                month3={monthlyUpgrade3}
                annual1={annualUpgrade1}
                annual2={annualUpgrade2}
                annual3={annualUpgrade3}
                onOptionChange={handleOptionChange}
              />
            </div>
          </Slide>
        )}
        <Slide
          direction="left"
          in={!step1}
          mountOnEnter
          unmountOnExit
          timeout={800}
        >
          <div>
            <Step2
              planData={planData}
              onPlanChange={handlePlanToggle}
              goBack={handleBack}
              closeModal={handleClose}
              onUpgradeSuccess={onUpgradeSuccess}
            />
          </div>
        </Slide>
      </Dialog>
    </div>
  );
};

export default AdvisorFreemiumModal;
