import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useMediaQuery,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import ACSnackbar from './../../ACSnackbar';
import PostPopUp from './../PopUp';
import { useAccount } from './../../../contexts/Account';
import { useCompanyExtraFeed } from '../../../contexts/CompanyFeed';

const useStyles = () => ({
  container: {
    width: '100%',
  },
  dialogTitle: {
    paddingBottom: '0px',
  },
  dialogContent: {
    paddingTop: '0px',
  },
});

function BoardMeetingPopUp(props) {
  const { open, closeAction, postData } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { isFreemium } = useAccount();
  const companyExtraFeed = useCompanyExtraFeed();
  const location = useLocation();

  return (
    <>
      <Dialog
        open={open}
        onClose={closeAction}
        fullWidth
        sx={classes.container}
      >
        <DialogTitle sx={classes.dialogTitle}>
          <Typography variant="old_font">Board Meeting</Typography>
        </DialogTitle>
        <DialogContent sx={classes.dialogContent}>
          <PostPopUp
            type="boardMeeting"
            postData={postData}
            editingPost={postData}
            editMode={postData ? true : false}
            callbackAfterPost={(newPost) => {
              if (
                newPost &&
                companyExtraFeed.addPost &&
                location.pathname === '/dashboard'
              ) {
                companyExtraFeed.addPost(newPost);
              }
              setShowSnackbar(true);
              closeAction();
            }}
            // TODO:
            // callbackAfterEdit={(newPost) => {
            //   if (
            //     newPost &&
            //     companyExtraFeed.updatePost &&
            //     location.pathname === '/dashboard'
            //   ) {
            //     companyExtraFeed.updatePost(newPost);
            //   }
            //   setShowSnackbar(true);
            //   closeAction();
            // }}
            callbackAfterCancel={() => {
              closeAction();
            }}
            automaticCalendarLink
          />
        </DialogContent>
      </Dialog>
      <ACSnackbar
        open={showSnackbar}
        text="Board Meeting Created!"
        severity="success"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
    </>
  );
}

export default BoardMeetingPopUp;
