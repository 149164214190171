import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

export function findTextNewUrl(newEditorState, newText, oldText) {
  try {
    const newState = newEditorState.getCurrentContent();
    const newRaw = JSON.stringify(convertToRaw(newState));
    let newJsonContent = JSON.parse(newRaw);
    const newTextBlocks = newText.split('\n');
    const oldTextBlocks = oldText.split('\n');

    // console.log({
    //   newJsonContent,
    //   // newText,
    //   // oldText,
    //   newTextBlocks,
    //   oldTextBlocks,
    // });
    for (let ii = 0; ii < newTextBlocks.length; ii++) {
      const newBlock = newTextBlocks[ii];
      const oldBlock = oldTextBlocks[ii];

      if (newBlock !== oldBlock) {
        let newLines = newBlock.split(' ');
        let oldLines = oldBlock.split(' ');
        let index = -1;
        for (let jj = 0; jj < newLines.length; jj++) {
          if (newLines[jj] !== oldLines[jj]) {
            index = jj;
            break;
          }
        }
        if (index > -1 && newLines[index]) {
          const newWord = newLines[index];
          // check if the word is a url
          const urlRegex = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$',
            'i'
          );
          if (urlRegex.test(newWord)) {
            console.log(newWord, 'Is a url');
            const rawFinalState = {
              ...newJsonContent,
            };

            // find if offset already exists
            let newLinkOffset = 0;
            for (let kk = 0; kk < index; kk++) {
              if (newLines[kk] !== newWord) {
                newLinkOffset += newLines[kk].length + 1;
              } else break;
            }
            const countKeys = Object.keys(newJsonContent.entityMap).length;
            const existentEntityRange = rawFinalState.blocks[
              ii
            ].entityRanges.find(
              (entityRange) => entityRange.offset === newLinkOffset
            );
            if (existentEntityRange) {
              // console.log({ existentEntityRange });
              return false;
            }

            rawFinalState.blocks[ii].entityRanges.push({
              offset: newLinkOffset,
              length: newWord.length,
              key: countKeys,
            });

            // console.log({ countKeys, newJsonContent });
            rawFinalState.entityMap[countKeys] = {
              type: 'LINK',
              mutability: 'MUTABLE',
              data: {
                url: newWord,
                targetOption: '_blank',
              },
            };
            // console.log({ rawFinalState, newLinkOffset });

            const finalContentState = convertFromRaw(rawFinalState);
            return EditorState.createWithContent(finalContentState);
          }
        }
      }
    }
  } catch (error) {
    console.log({ error });
  }
  return false;
}
