import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Autocomplete,
  Typography,
  TextField,
  useMediaQuery,
  FormControl,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ACButton from '../ACButton';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { zonedTimeToUtc } from 'date-fns-tz';
import FileUploader from '../Post/PopUpFileUploader';
import ACSnackbar from '../ACSnackbar';
import {
  companyUploadPostCall,
  companyUploadAttachmentsCall,
  companyUpdatePostCall,
  companyAddPostVideoCall,
  companyAddBoardMeetingVideoCall,
} from '../../services/company_services';
import { useAccount } from '../../contexts/Account';
import Carousel from '../Post/Carousel';
import CarouselCard from '../Post/Carousel/CarosuelCard';
import { uploadToS3 } from '../../services/Amplify/amplify-files-upload';
import ModeratorImageUploader from './moderatorImageUploader';
import { Enviroments } from '../../services/utils/types';
import defaultTimezones from './timeZones.json';
import { ReactComponent as ImageIcon } from './picture.svg';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PermissionRadio from './Permissions';
import TextEditor from './textEditor';

const useStyles = (isSM) => ({
  container: {
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isSM ? '15px' : '0px',
    paddingRight: isSM ? '15px' : '0px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isSM ? 'start' : 'end',
    alignItems: isSM ? 'start' : 'end',
    position: 'relative',
    width: '100%',
    color: 'black',
    top: isSM ? 65 : 'auto',
    marginBottom: isSM ? '-35px' : '-53px',
    marginLeft: isSM ? '-13px' : '0px',
    zIndex: isSM ? 1001 : 999,
  },
  titleInput: {
    marginTop: '1px',
    border: '1.5px',
    width: '100%',
  },
  postInput: {
    minHeight: '190px',
    width: '100%',
    alignItems: 'baseline',
    marginTop: '13px',
    marginBottom: '30px',
    paddingLeft: isSM ? 0 : '25px',
    paddingRight: isSM ? 0 : '25px',
  },
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95px',
    padding: '0px 15px 0px 25px',
  },
  featureImageText: {
    marginTop: '7px',
    marginBottom: '7px',
  },
  warningText: {
    color: 'error.main',
  },
  remove: {
    display: 'none',
  },
  editButtonsContainer: {
    display: 'flex',
  },
  addMargin: {
    marginRight: '15px',
  },
  addMarginButton: {
    marginRight: '8px',
    marginLeft: '17px',
  },
  inputVideo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    marginTop: '10px',
    padding: isSM ? '0px 8px 0px 8px' : '0px 15px 0px 25px',
  },
  button: {
    borderRadius: '2px',
  },
  buttonVideoContainer: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    color: '#97A3B7',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  iconVideo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    width: '30px',
    height: '30px',
    marginRight: '7px',
    color: 'black',
  },
  warningRow: {
    position: 'absolute',
    bottom: '0px',
    right: '40px',
  },
  videoPreview: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0px',
    width: '94%',
    marginLeft: '25px',
    overflow: 'hidden',
    marginTop: '5px',
  },
  videoFrame: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  linksFields: {
    minWidth: '400px',
    marginBottom: '15px',
  },
  moderatorFields: {
    minWidth: '250px',
    marginBottom: '15px',
  },
  moderatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
  },
  avatarContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  advisorInfoContainer: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  meetingTimeContainer: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  buttonContainer: {
    width: '146px',
    margin: '25px 25px',
    position: 'absolute',
    bottom: 0,
    right: '15px',
    zIndex: 1000,
  },
  sendButton: {
    color: 'white',
    width: '146px',
    height: '49px',
    borderRadius: '25px',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: -0.43,
  },
});

function PostPopUp(props) {
  const {
    onChange,
    type,
    callbackAfterPost,
    editMode,
    editingPost,
    callbackAfterEdit,
    callbackAfterCancel,
    postData,
    setPostHasContent = () => {},
  } = props;
  const inputRef = useRef();
  const videoRef = useRef();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [postText, setPostText] = useState(editingPost ? editingPost.body : '');
  const [postTitle, setPostTitle] = useState('');
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [uploaderOutput, setUploaderOutput] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { accountDetails, asLoggedUserDetails, currentUserInfo } = useAccount();
  const [isLoading, setIsLoading] = useState(0);
  const [selectedId, setSelectedId] = useState();
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [textIsShorter, setTextIsShorter] = useState(false);

  const [openVideo, setOpenVideo] = useState(
    editingPost && editingPost.video && editingPost.video.url
  );
  const [openImage, setOpenImage] = useState(false);
  const [videoUrl, setVideoUrl] = useState(
    editingPost && editingPost.video ? editingPost.video.url : ''
  );
  const [embededUrl, setEmbededUrl] = useState(
    editingPost && editingPost.video ? editingPost.video.url : ''
  );
  const [videoType, setVideoType] = useState(
    editingPost && editingPost.video ? editingPost.video.type : 'youtube'
  );
  const [videoUrlEror, setVideoUrlError] = useState(false);

  const [streamLink, setStreamLink] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [calendarLink, setCalendarLink] = useState('');
  const [moderatorName, setModeratorName] = useState('');
  const [moderatorTitle, setModeratorTitle] = useState('');
  const [moderatorAvatar, setModeratorAvatar] = useState('');
  const [moderatorAvatarEdited, setModeratorAvatarEdited] = useState(false);
  const [meetingTime, setMeetingTime] = useState();
  const [meetingTimeZone, setMeetingTimeZone] = useState('');
  const [timeZoneList] = useState(
    Object.keys(defaultTimezones).map((key) => ({
      id: defaultTimezones[key].toString(),
      label: key.toString(),
    }))
  );
  const [timeZoneListLabels] = useState(
    Object.keys(defaultTimezones).map((key) => key)
  );
  const [meetingTimeZoneInput, setMeetingTimeZoneInput] = useState('');
  const [permission, setPermission] = useState('Board Owner');
  const [hideToAdvisors, setHideToAdvisors] = useState(true);
  const [hideToContacts, setHideToContacts] = useState(true);

  useEffect(() => {
    if (!moderatorAvatar && postData) {
      setModeratorAvatarEdited(true);
    }
  }, [moderatorAvatar]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [type]);

  useEffect(() => {
    if (openImage && openVideo) {
      setOpenImage(false);
    }
  }, [openVideo]);

  useEffect(() => {
    if (openImage && openVideo) {
      setOpenVideo(false);
    }
  }, [openImage]);

  useEffect(() => {
    setPostHasContent(
      postText.length > 0 || uploaderOutput.length > 0 || videoUrl.length > 0
    );
  }, [postText, uploaderOutput, videoUrl]);

  useEffect(() => {
    if (type === 'boardMeeting' && postData) {
      const localeHour = new Date(postData.meetingTime);
      let strTime = localeHour.toLocaleString('en-US', {
        timeZone: `${postData.meetingTimeZone}`,
      });
      const streamingDate = new Date(strTime);

      setPostText(postData.body);
      setPostTitle(postData.postTitle);
      setStreamLink(postData.streamLink);
      setMeetingLink(postData.meetingLink);
      setCalendarLink(postData.calendarLink);
      setModeratorName(postData.moderatorName);
      setModeratorTitle(postData.moderatorTitle);
      setMeetingTime(streamingDate);
      const timezone = timeZoneList.find(
        (x) => x.id == postData.meetingTimeZone
      );
      setMeetingTimeZone(postData.meetingTimeZone);
      setMeetingTimeZoneInput(timezone.label);
    }
  }, []);

  function setAttachmentsArray(value) {
    uploaderOutput.push(value);
    setUploaderOutput([...uploaderOutput]);
  }

  function deleteArrayElement(data) {
    const filteredArray = uploaderOutput.filter((item) => item.id !== data);
    setUploaderOutput([...filteredArray]);
  }

  function selectArrayElement(data) {
    if (data === -1) {
      setSelectedId('');
    } else {
      setSelectedId(uploaderOutput[data].id);
    }
  }

  async function makePost(postType) {
    try {
      const makePostCall = await companyUploadPostCall({
        COMPANY_ID: accountDetails.id,
        TYPE: postType,
        BODY: postText,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        HIDE_TO_ADVISORS: hideToAdvisors,
        HIDE_TO_CONTACTS: hideToContacts,
      });
      const post = makePostCall.data.addPost;
      if (openVideo && videoType && videoUrl) {
        const postVideoResponse = await companyAddPostVideoCall({
          COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
          COMPANY_ID: accountDetails.id,
          POST_ID: post.id,
          VIDEO_TYPE: videoType,
          VIDEO_URL: embededUrl,
        });
        post.video = postVideoResponse.data.addPostVideo.video;
      }
      if (uploaderOutput.length > 0 && openImage) {
        const attachments = await companyUploadAttachmentsCall({
          POST_ID: post.id,
          ATTACHMENTS_ARRAY: uploaderOutput.map((data) => {
            return JSON.stringify({
              id: data.id,
              filename: data.name,
              url: data.url,
            });
          }),
          PRIMARY_ATTACHMENT: selectedId,
          USER_ID: asLoggedUserDetails.userId,
          COMPANY_ID: accountDetails.id,
        });
        post.attachments = [...attachments.data.uploadAttachments.attachments];
      }
      if (
        type === 'boardMeeting' &&
        moderatorAvatar &&
        streamLink &&
        calendarLink &&
        meetingLink &&
        moderatorName &&
        moderatorTitle &&
        postTitle
      ) {
        let bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES;
        if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
          bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES.replace(
            'us-west-2',
            'us-east-1'
          );
        }
        const imageId = `${uuidv4()}.${moderatorAvatar.type.split('/')[1]}`;
        await uploadToS3({
          BLOB_LOAD: moderatorAvatar,
          CONTENT_TYPE: moderatorAvatar.type,
          FILE_ID: imageId,
          S3_BUCKET: bucketImages,
        });
        const uploadedAvatarId = `public/${imageId}`;
        let dateUTC = zonedTimeToUtc(meetingTime, meetingTimeZone);
        if (dateUTC != 'Invalid Date') {
          dateUTC = dateUTC.toISOString();
          await companyAddBoardMeetingVideoCall({
            COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
            COMPANY_ID: accountDetails.id,
            POST_ID: post.id,
            STREAM_LINK: streamLink,
            MEETING_LINK: meetingLink,
            CALENDAR_LINK: calendarLink,
            MODERATOR_NAME: moderatorName,
            MODERATOR_TITLE: moderatorTitle,
            MODERATOR_AVATAR: uploadedAvatarId,
            POST_TITLE: postTitle,
            MEETING_TIME: dateUTC,
            MEETING_TIMEZONE: meetingTimeZone,
            BODY: postText,
            IS_EDIT: false,
          });
        }
      }
      if (callbackAfterPost) {
        callbackAfterPost(post);
      }
    } catch (err) {
      console.log(err);
      throw new Error('ERROR at make Post', err);
    }
  }

  async function makeEdit() {
    try {
      const makeEditCall = await companyUpdatePostCall({
        BODY: postText,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_ID: accountDetails.id,
        POST_ID: editingPost.id,
        VIDEOTYPE: openVideo ? videoType || '' : '',
        VIDEOURL: openVideo ? embededUrl || '' : '',
      });

      const post = makeEditCall.data.updatePost;

      if (callbackAfterEdit) {
        callbackAfterEdit(post);
      }
    } catch (err) {
      throw new Error('ERROR at edit Post', err);
    }
  }

  async function makeBoardMeetingEdit(postType) {
    try {
      if (
        postType === 'boardMeeting' &&
        moderatorAvatar &&
        streamLink &&
        meetingLink &&
        calendarLink &&
        moderatorName &&
        moderatorTitle &&
        postTitle
      ) {
        let uploadedAvatarId;
        if (moderatorAvatarEdited) {
          let bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES;
          if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
            bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES.replace(
              'us-west-2',
              'us-east-1'
            );
          }
          const imageId = `${uuidv4()}.${moderatorAvatar.type.split('/')[1]}`;
          await uploadToS3({
            BLOB_LOAD: moderatorAvatar,
            CONTENT_TYPE: moderatorAvatar.type,
            FILE_ID: imageId,
            S3_BUCKET: bucketImages,
          });
          uploadedAvatarId = `public/${imageId}`;
        } else {
          uploadedAvatarId = postData.moderatorAvatar;
        }

        let dateUTC = zonedTimeToUtc(meetingTime, meetingTimeZone);
        if (dateUTC != 'Invalid Date') {
          dateUTC = dateUTC.toISOString();
          await companyAddBoardMeetingVideoCall({
            COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
            COMPANY_ID: accountDetails.id,
            POST_ID: postData.id,
            STREAM_LINK: streamLink,
            MEETING_LINK: meetingLink,
            CALENDAR_LINK: calendarLink,
            MODERATOR_NAME: moderatorName,
            MODERATOR_TITLE: moderatorTitle,
            MODERATOR_AVATAR: uploadedAvatarId,
            POST_TITLE: postTitle,
            MEETING_TIME: dateUTC,
            MEETING_TIMEZONE: meetingTimeZone,
            BODY: postText,
            IS_EDIT: true,
          });
        }
      }
      if (callbackAfterPost) {
        callbackAfterPost(postData);
      }
    } catch (err) {
      throw new Error('ERROR at edit Post', err);
    }
  }

  async function handlePost(postType) {
    setIsLoading(1);

    try {
      if (!editMode) {
        await makePost(postType);
      } else if (postType === 'boardMeeting') {
        await makeBoardMeetingEdit(postType);
      } else {
        await makeEdit();
      }
      if (onChange) {
        onChange(false);
      }
    } catch (err) {
      setIsLoading(0);
      setShowSnackbar(true);
    }
  }

  function makePostSelector() {
    handlePost(type);
  }

  function editButtons() {
    return (
      <div style={classes.editButtonsContainer}>
        <div style={classes.addMargin}>
          <ACButton color="secondary" onClick={() => callbackAfterCancel()}>
            Cancel
          </ACButton>
        </div>
        <ACButton
          classes={{ root: 'button' }}
          color="primary"
          loading={isLoading ? 1 : 0}
          onClick={() => makePostSelector()}
        >
          Save
        </ACButton>
      </div>
    );
  }

  function validateVideoUrl(url) {
    if (url === undefined || url === '' || url.length === 0) {
      setVideoUrlError(false);
      setEmbededUrl(false);
      return;
    }

    let regExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let match = url.match(regExp);
    if (match && match[1].length === 11) {
      setVideoUrlError(false);
      setVideoType('youtube');
      setEmbededUrl(`https://www.youtube.com/embed/${match[1]}?autoplay=0`);
      return;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      setVideoUrlError(false);
      setVideoType('vimeo');
      setEmbededUrl(`https://player.vimeo.com/video/${match[3]}?autoplay=0`);
      return;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:event\/(?:\w+\/)?|event\/([^\/]*)\/event\/|event\/(\d+)\/event\/|event\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      setVideoUrlError(false);
      setVideoType('vimeo');
      setEmbededUrl(`https://vimeo.com/event/${match[3]}/embed?autoplay=0`);
      return;
    }

    setVideoUrlError(true);
    setEmbededUrl(false);
    return;
  }

  function disablePostButton() {
    if (isLoading) return true;
    if (textIsShorter) return true;
    if (postText.length === 0) return true;
    if (openVideo && videoUrlEror) return true;
    if (openVideo && videoUrl.length === 0) return true;
    if (type === 'boardMeeting') {
      if (!calendarLink) return true;
      if (!calendarLink) return true;
      if (!streamLink) return true;
      if (!meetingLink) return true;
      if (!moderatorTitle) return true;
      if (!moderatorName) return true;
      if (!moderatorAvatar) return true;
      if (!postTitle) return true;
      if (!meetingTime) return true;
      if (!meetingTimeZone) return true;
      if (zonedTimeToUtc(meetingTime, meetingTimeZone) == 'Invalid Date')
        return true;
    }
    return false;
  }

  return (
    <div style={classes.container}>
      <ACSnackbar
        open={showSnackbar}
        text={
          type === 'question'
            ? t('POST-QUESTION-ERROR')
            : t('POST-UPDATE-ERROR')
        }
        severity="error"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
      />
      {type === 'boardMeeting' ? (
        <div style={{ padding: '0px 15px 0px 25px' }}>
          <TextField
            placeholder={'Title'}
            sx={classes.titleInput}
            onChange={(e) => {
              setPostTitle(e.target.value);
            }}
            value={postTitle}
            autoComplete="off"
            id="postPopUpTitle"
            name="postPopUpTitle"
            variant="outlined"
            size="small"
          />
        </div>
      ) : null}
      <div style={classes.buttonGroup}>
        <div style={classes.editButtonsContainer}>
          <div style={classes.addMarginButton}>
            <ACButton
              color="primary"
              size="medium"
              variant="text"
              onClick={() => {
                setOpenImage(!openImage);
              }}
            >
              <ImageIcon style={{ ...classes.iconVideo, width: 24 }} />
              <div style={classes.buttonVideoContainer}>Add image</div>
            </ACButton>
          </div>
          <div style={classes.addMargin}>
            <ACButton
              color="primary"
              size="medium"
              variant="text"
              onClick={() => {
                setOpenVideo(!openVideo);
                if (videoRef.current) videoRef.current.focus();
              }}
            >
              <OndemandVideoIcon style={{ ...classes.iconVideo, width: 25 }} />
              <div style={classes.buttonVideoContainer}>add video</div>
            </ACButton>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: isSM ? '0px 5px 0px 10px' : null,
        }}
        id="postPopUpTextArea"
      >
        <div style={classes.postInput}>
          <TextEditor
            type={type}
            initialText={editingPost ? editingPost.body : ''}
            setText={setPostText}
            setWarning={setTextIsShorter}
          />
        </div>
      </div>

      {type === 'insight' || type === 'meeting' ? (
        <PermissionRadio
          permission={permission}
          setPermission={setPermission}
          setHideToAdvisors={setHideToAdvisors}
          setHideToContacts={setHideToContacts}
        />
      ) : null}
      {type == 'boardMeeting' ? (
        <div>
          <div style={classes.meetingTimeContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                value={meetingTime}
                label="Meeting Time"
                onChange={(newValue) => {
                  setMeetingTime(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{ width: '50%', marginRight: 10 }}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Autocomplete
              options={timeZoneListLabels}
              value={meetingTimeZoneInput}
              onChange={(event, vals) => {
                const timezone = timeZoneList.find((x) => x.label == vals);
                if (timezone) {
                  setMeetingTimeZone(timezone.id);
                } else {
                  setMeetingTimeZone('');
                }
                setMeetingTimeZoneInput(vals);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Time Zone"
                  style={{ width: '300px' }}
                />
              )}
            />
          </div>
          <TextField
            size="small"
            onChange={(e) => {
              setStreamLink(e.target.value);
            }}
            variant="outlined"
            label="Live Stream Link"
            value={streamLink}
            sx={classes.linksFields}
          />
          <TextField
            size="small"
            onChange={(e) => {
              setMeetingLink(e.target.value);
            }}
            variant="outlined"
            label="Meeting Link"
            value={meetingLink}
            sx={classes.linksFields}
          />
          <TextField
            size="small"
            onChange={(e) => {
              setCalendarLink(e.target.value);
            }}
            variant="outlined"
            label="Calendar Link"
            value={calendarLink}
            helperText={
              videoUrlEror ? 'Video link does not match a hosting service' : ''
            }
            sx={classes.linksFields}
          />
          <div>
            <Typography>Moderator</Typography>
          </div>
          <div style={classes.moderatorContainer}>
            <div style={classes.avatarContainer}>
              <ModeratorImageUploader
                resultFiles={(images) => setModeratorAvatar(images.image)}
                preloadUrl={postData ? postData.moderatorAvatar : null}
              />
            </div>
            <div style={classes.advisorInfoContainer}>
              <TextField
                onChange={(e) => {
                  setModeratorName(e.target.value);
                }}
                label="Name"
                variant="outlined"
                size="small"
                value={moderatorName}
                sx={classes.moderatorFields}
              />
              <TextField
                onChange={(e) => {
                  setModeratorTitle(e.target.value);
                }}
                label="Title"
                variant="outlined"
                size="small"
                value={moderatorTitle}
                sx={classes.moderatorFields}
              />
            </div>
          </div>
        </div>
      ) : null}
      {uploaderOutput.length !== 0 && !editMode ? (
        <>
          <section style={classes.carouselContainer}>
            <Carousel
              deleteElement={deleteArrayElement}
              slideSelected={selectArrayElement}
              isEdit
            >
              {uploaderOutput.map((slide) => {
                return (
                  <CarouselCard
                    key={slide.id}
                    img={slide.url}
                    name={slide.name}
                  />
                );
              })}
            </Carousel>
            <Typography
              sx={classes.featureImageText}
              variant="caption_old_font"
              color="primary"
            >
              {t('POST-POP-UP-FEATURED_IMAGE')}
            </Typography>
          </section>
        </>
      ) : null}
      {openImage ? (
        <div
          style={
            editMode || type == 'boardMeeting'
              ? classes.remove
              : { padding: isSM ? '0px 8px 0px 8px' : '0px 15px 0px 25px' }
          }
        >
          <FileUploader resultFiles={setAttachmentsArray} />
        </div>
      ) : null}
      {openVideo && (
        <div style={classes.inputVideo}>
          <FormControl fullWidth>
            <TextField
              inputRef={videoRef}
              autoFocus
              id="urlInput"
              size="small"
              onChange={(e) => {
                setVideoUrl(e.target.value);
                validateVideoUrl(e.target.value);
              }}
              variant="outlined"
              label="Add your Youtube or Vimeo URL"
              value={videoUrl}
              error={videoUrlEror}
              helperText={
                videoUrlEror
                  ? 'Video link does not match a hosting service'
                  : ''
              }
              fullWidth
            />
          </FormControl>
        </div>
      )}
      {embededUrl ? (
        <>
          <div style={classes.videoPreview}>
            <iframe
              src={embededUrl}
              frameBorder="0"
              allowFullScreen
              title="previewVideo"
              style={classes.videoFrame}
            />
          </div>
        </>
      ) : null}
      <Box sx={classes.buttonContainer}>
        {editMode ? (
          editButtons()
        ) : (
          <Box
            onClick={() => {
              if (textIsShorter) {
                setShowWarningMessage(true);
                setTimeout(() => {
                  setShowWarningMessage(false);
                }, 3000);
              }
            }}
          >
            <ACButton
              variant="contained"
              style={{
                ...classes.sendButton,
                background: disablePostButton() ? '#acc5fc' : '#3171F6',
              }}
              onClick={makePostSelector}
              disabled={disablePostButton()}
              loading={isLoading}
              id="PostPopUpButton"
            >
              Post
            </ACButton>
          </Box>
        )}
      </Box>
      <div style={classes.warningRow}>
        {showWarningMessage ? (
          <Typography sx={classes.warningText} variant="caption_old_font">
            {t('POST-POP-UP-WARNING')}
          </Typography>
        ) : null}
      </div>
    </div>
  );
}

export default PostPopUp;
