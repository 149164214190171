import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from './../../../contexts/Account';
import { Dialog, Slide, useMediaQuery } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Step1 from './Step1';
import Step2 from './Step2';
import eventBus, { eventBusValues } from './../../../eventBus';
import { getCompanyRecurlyPlan } from './../../../services/company_services';
import { getAdvisorPlanUpgrades } from './../../../services/advisor_services';
import AdvisoryCloudPlusOnboarding from '../../../hooks/AdvisoryCloudPlusOnboarding';

const CompanyFreemiumModal = () => {
  const { accountDetails, upgradeFreemiumAccount } = useAccount();
  const { search } = useLocation();

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [monthlyUpgradePlus, setMonthlyUpgradePlus] = useState('');
  const [monthlyUpgradeManaged, setMonthlyUpgradeManaged] = useState('');
  const [annualUpgradePlus, setAnnualUpgradePlus] = useState('');
  const [annualUpgradeManaged, setAnnualUpgradeManaged] = useState('');
  const [planData, setPlanData] = useState({ plan: 'mo', option: 1, data: '' });
  const [hideBack, setHideBack] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('');

  const handleContinue = () => {
    setStep1(false);
  };

  const handleContinueStep3 = () => {
    setStep1(false);
    setStep2(true);
  };

  const handleBack = () => {
    setHideBack(false);
    setStep1(true);
  };

  const handleUpgradeComplete = () => {
    if (pendingAction) {
      pendingAction();
      setPendingAction(null);
    }
  };

  function handleClose() {
    setOpenModal(false);
    setStep1(true);
    setHideBack(false);
    if (step2) {
      upgradeFreemiumAccount(selectedPlan, 'start_your_advisorycloud');
      handleUpgradeComplete();
    }
  }

  const handleOptionChange = (plan, option, data) => {
    if (option === 1) {
      setPlanData({ plan: 'mo', option: 1, data: monthlyUpgradePlus });
    } else {
      setPlanData({ plan: 'mo', option: 2, data: monthlyUpgradeManaged });
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const planResponses = await getCompanyRecurlyPlan({
          recurlyPlanCode: accountDetails.recurlyPlanCode,
        });

        const {
          acPlusUpgradeOptionOne,
          acPlusUpgradeOptionTwo,
          freemiumUpgradeOne,
          freemiumUpgradeTwo,
        } = planResponses.data.getRecurlyPlan;

        if (acPlusUpgradeOptionOne) {
          const planResponsesMonthPlus = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: acPlusUpgradeOptionOne,
          });
          setMonthlyUpgradePlus(
            planResponsesMonthPlus.data.getFreeTrialUpgrades[0]
          );
        }
        if (acPlusUpgradeOptionTwo) {
          const planResponsesAnnualPlus = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: acPlusUpgradeOptionTwo,
          });
          setAnnualUpgradePlus(
            planResponsesAnnualPlus.data.getFreeTrialUpgrades[0]
          );
        }
        if (freemiumUpgradeOne) {
          const planResponsesMonthManaged = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: freemiumUpgradeOne,
          });
          setMonthlyUpgradeManaged(
            planResponsesMonthManaged.data.getFreeTrialUpgrades[0]
          );
        }
        if (freemiumUpgradeTwo) {
          const planResponsesAnnualManaged = await getAdvisorPlanUpgrades({
            RECURLY_PLAN_CODE: freemiumUpgradeTwo,
          });
          setAnnualUpgradeManaged(
            planResponsesAnnualManaged.data.getFreeTrialUpgrades[0]
          );
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
    eventBus.on(eventBusValues.triggerCompanyUpgradeAction, (data) => {
      if (data && data.planCode === 'managed') {
        setPlanData({ plan: 'mo', option: 2, data: monthlyUpgradeManaged });
        handleContinue();
        setIsFirstLoad(false);
        setHideBack(true);
      }
      if (data && data.planCode === 'plus') {
        setPlanData({ plan: 'mo', option: 1, data: monthlyUpgradePlus });
        handleContinue();
        setIsFirstLoad(false);
        setHideBack(true);
      }
      if (data && data.action) {
        setPendingAction(() => data.action);
      }
      setOpenModal(true);
    });

    const profileParam = new URLSearchParams(search).get(
      'b2b_freemium_upgrade_popup'
    );

    if (profileParam) {
      if (profileParam === 'step1') {
        setIsFirstLoad(true);
      } else if (profileParam === 'mab') {
        setPlanData({ plan: 'mo', option: 2, data: monthlyUpgradeManaged });
        setIsFirstLoad(false);
        handleContinue();
        setHideBack(true);
      } else if (profileParam === 'sya') {
        setPlanData({ plan: 'mo', option: 1, data: monthlyUpgradePlus });
        setIsFirstLoad(false);
        handleContinue();
        setHideBack(true);
      }
      setOpenModal(true);
    }

    fetchData();
    return () => {
      eventBus.remove(eventBusValues.triggerCompanyUpgradeAction);
    };
  }, []);

  return (
    <div>
      <Dialog
        open={openModal}
        fullScreen={isSM}
        onClose={handleClose}
        PaperProps={{
          id: 'companyFreemiumUpgradeModal',
          sx: {
            borderRadius: isSM ? '0px' : '15px',
            boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
            height: { xxs: '100%', sm: '742px' },
            width: { xxs: '100%', sm: '920px' },
          },
        }}
      >
        {isSM && (
          <div
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
          >
            <Clear onClick={handleClose} />
          </div>
        )}

        {isFirstLoad ? (
          <div>
            <Step1
              isUserSegment={accountDetails.userSegment}
              onContinue={() => {
                handleContinue();
                setHideBack(false);
                setIsFirstLoad(false);
              }}
              onOptionChange={handleOptionChange}
              setOpenModal={setOpenModal}
            />
          </div>
        ) : (
          <Slide direction="right" in={step1} mountOnEnter unmountOnExit>
            <div>
              <Step1
                isUserSegment={accountDetails.userSegment}
                onContinue={handleContinue}
                onOptionChange={handleOptionChange}
                setOpenModal={setOpenModal}
              />
            </div>
          </Slide>
        )}
        <Slide
          direction="left"
          in={!step1 && !step2}
          mountOnEnter
          unmountOnExit
          timeout={800}
        >
          <div>
            <Step2
              planData={planData}
              goBack={handleBack}
              closeModal={handleClose}
              month1={monthlyUpgradePlus}
              month2={monthlyUpgradeManaged}
              annual1={annualUpgradePlus}
              annual2={annualUpgradeManaged}
              hideBack={hideBack}
              pendingAction={pendingAction}
              handleContinueStep3={handleContinueStep3}
              setSelectedPlanFinal={setSelectedPlan}
            />
          </div>
        </Slide>

        <Slide
          direction="left"
          in={step2}
          mountOnEnter
          unmountOnExit
          timeout={800}
        >
          <div style={{ marginTop: '50px' }}>
            <AdvisoryCloudPlusOnboarding />
          </div>
        </Slide>
      </Dialog>
    </div>
  );
};

export default CompanyFreemiumModal;
