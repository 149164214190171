import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  useMediaQuery,
  Fab,
  Box,
  Backdrop,
  Typography,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import AdvisorAvatarMenu from './AdvisorAvatarMenu';
import UpcomingMeetingBanner from './UpcomingMeetingBanner';
import ConversationNotification from './../../Conversation/Notification';
import { useAccount } from './../../../contexts/Account';
import { getAdvisorImagePath } from './../../../services/utils';
import { getNearestMeetingCompany } from './../../../services/advisor_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import { getAdvisorQueryParams } from '../../../utils/queryParams';

const useStyles = ({
  isSM,
  isSM2,
  isSM3,
  isSM4,
  isWrapped,
  isSMOld,
  isMessagesSection,
  isBoardroomSection,
  isAccountSection,
  isFreeTrial,
  showBanner,
}) => ({
  outerContainer: {
    height: '75px',
  },
  container: {
    width: !isSM ? '80%' : '100%',
    maxWidth: !isSM ? 'calc(100% - 280px)' : '100%',
    minWidth: !isSM ? 'calc(100% - 350px)' : '100%',
    height: '75px',
    backgroundColor: 'white',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: isSM ? '10px' : '20px',
    alignItems: 'center',
    paddingRight: isSM ? '20px' : isSM3 ? '67px' : '70px',
    zIndex: 2,
    position: 'fixed',
    right: '0px',
    top: isFreeTrial && isAccountSection ? '50px' : null,
    border: '1px solid #F0F3F5',
    boxShadow: 'none',
    borderLeft: isMessagesSection || isBoardroomSection ? null : 'none',
  },
  logoContainer: {
    marginTop: '0px',
  },
  navContainer: {
    display: 'flex',
    flex: 'auto',
    height: '75px',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '0px',
  },
  buttonsContainer: {
    display: isSM ? 'none' : 'flex',
    flex: 'auto',
    height: '75px',
    flexDirection: 'row',
    justifyContent: isWrapped ? 'left' : '',
    alignItems: 'center',
  },
  selectedNavLink: {
    fontWeight: 'bold',
    color: '#232B35',
  },
  profileContainer: {
    height: '75px',
    display: 'flex',
    marginLeft: 'auto',
    flexDirection: 'row',
    float: 'right',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    right: isSMOld ? '26px' : '70px',
  },
  upcomingMeetingContainer: {
    height: '75px',
    display: 'flex',
    flexDirection: 'row',
    float: 'right',
    alignItems: 'center',
    paddingRight: '30px',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    color: '#232B35',
    cursor: 'pointer',
    padding: isSM2 ? '6px 7px' : '6px 8px',
    minWidth: '64px',
    boxSizing: 'border-box',
    alignItems: 'center',
    position: 'relative',
    margin: '0px',
    display: 'inline-flex',
    '&:hover': { backgroundColor: 'transparent' },
    marginRight: isSM3 ? (isSM4 ? '17px' : '18px') : '20px',
  },
  optionsContainer: {
    width: '300px',
    height: '220px',
    position: 'absolute',
    top: isFreeTrial || showBanner ? '50px' : '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
  },
  menuItemsContainer: {
    marginTop: '15px',
    width: '210px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 10px rgba(230, 237, 254, 0.25)',
    border: '1px solid #F5F7FA',
    borderRadius: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
  },
});

function AdvisorUpperBar({ onAvatarMenuIconCLick }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1060]));
  const isSM2 = useMediaQuery((theme) => theme.breakpoints.down([1090]));
  const isSM3 = useMediaQuery((theme) => theme.breakpoints.down([1077]));
  const isSM4 = useMediaQuery((theme) => theme.breakpoints.down([1067]));
  const isSMOld = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1350]));

  const location = useLocation();
  const isMessagesSection = location.pathname.includes('messages');
  const isBoardroomSection = location.pathname.includes('/boardroom');
  const isAccountSection = location.pathname.includes('/account');
  const {
    accountDetails,
    conversations,
    currentPlan,
    isFreeTrial,
    isFreemium,
  } = useAccount();
  const { salesforceValues } = currentPlan;
  const showBanner =
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const classes = useStyles({
    isSM,
    isSM2,
    isSM3,
    isSM4,
    isWrapped,
    isSMOld,
    isMessagesSection,
    isBoardroomSection,
    isAccountSection,
    isFreeTrial,
    showBanner,
  });
  const { t } = useTranslation();
  const [bannerCompany, setBannerCompany] = useState(false);
  const [openAdvisoryBoards, setOpenAdvisoryBoards] = useState(false);
  const [drawerIsOnEditingMode, setDrawerIsOnEditingMode] = useState(false);
  function getUnreadMessages() {
    let totalUnread = 0;
    conversations.forEach((conv) => {
      totalUnread += conv.unreadCount;
    });
    return totalUnread;
  }
  const [localUnreadMessages, setLocalUnreadMessages] = useState(
    getUnreadMessages()
  );

  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const nearestMeetingCompany = await getNearestMeetingCompany({
        advisorId: accountDetails.id,
      });

      if (
        nearestMeetingCompany.data &&
        nearestMeetingCompany.data.getNearestMeetingCompany
      ) {
        setBannerCompany(nearestMeetingCompany.data.getNearestMeetingCompany);
      }
    }

    fetchData();
  }, []);

  function editingAdvisorModalEvent() {
    eventBus.dispatch(eventBusValues.drawerTryToCloseOnEdit);
  }

  function CustomMenuItem({
    label,
    route,
    redirectsTo,
    newTag,
    messagesTag,
    id,
  }) {
    function handleMenuItem() {
      if (location.pathname === route) {
        return;
      }
      if (!drawerIsOnEditingMode) {
        if (redirectsTo) {
          window.location.assign(redirectsTo);
        } else {
          eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
          history.push(route);
        }
      } else {
        editingAdvisorModalEvent();
      }
    }

    return (
      <div id={id}>
        <div style={classes.button} onClick={handleMenuItem}>
          <div
            style={location.pathname === route ? classes.selectedNavLink : {}}
          >
            {label}
          </div>
        </div>
        {newTag && !(messagesTag && localUnreadMessages) && (
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            role="none"
            onClick={handleMenuItem}
            style={{
              height: 18,
              position: 'relative',
              top: -12,
              right: 10,
              color: '#1976d2',
              fontSize: 9,
              fontWeight: 'bold',
              paddingTop: 2,
              boxShadow: 'none',
              backgroundColor: '#E7EDFD',
            }}
          >
            New
          </Fab>
        )}
        {messagesTag && (
          <ConversationNotification value={localUnreadMessages} />
        )}
      </div>
    );
  }

  function HoverMenuItem({
    label,
    newTag,
    id,
    open,
    setOpen = () => {},
    options = [],
    paths = [],
  }) {
    const boxRef = useRef(null);
    const paperRef = useRef(null);

    useEffect(() => {
      try {
        // sets the menu itmes under the nav bar button
        const boxNode = ReactDOM.findDOMNode(boxRef.current);
        const paperNode = ReactDOM.findDOMNode(paperRef.current);
        const boxRect = boxNode.getBoundingClientRect();
        paperNode.style.left = `${boxRect.left - 66}px`;
      } catch (error) {
        console.log(error);
      }
    }, []);

    function getNewTag({ top, right }) {
      return (
        <Fab
          variant="extended"
          size="small"
          color="secondary"
          role="none"
          style={{
            height: 18,
            position: 'absolute',
            color: '#3171F6',
            fontSize: '10px',
            fontWeight: '600',
            boxShadow: 'none',
            backgroundColor: '#D7F1FD',
            textTransform: 'none',
            top,
            right,
          }}
        >
          New
        </Fab>
      );
    }

    return (
      <Box id={id} style={classes.button} onMouseOver={() => setOpen(true)}>
        <Box
          ref={boxRef}
          style={
            paths.includes(location.pathname) ? classes.selectedNavLink : {}
          }
        >
          {label}
        </Box>
        {newTag &&
          getNewTag({
            top: '-8px',
            right: '-18px',
          })}
        <ArrowDropDown sx={{ width: '16px', marginLeft: '3px' }} />
        <Backdrop sx={{ backgroundColor: 'transparent' }} open={open}>
          <Paper
            ref={paperRef}
            sx={classes.optionsContainer}
            elevation={0}
            onMouseLeave={() => setOpen(false)}
          >
            <Box sx={classes.menuItemsContainer}>
              {options.map((option) => (
                <Box
                  id={option.id || null}
                  key={option.label}
                  sx={{
                    padding: '10px 0px',
                  }}
                  onClick={option.onClick}
                  display="flex"
                  position="relative"
                >
                  {option.icon && (
                    <img
                      src={option.icon}
                      alt={option.label}
                      style={{ marginRight: option.mr || '12px' }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontWeight:
                        location.pathname === option.route ? 'bold' : '500',
                      fontSize: '14px',
                      color: '232B35',
                      paddingTop: '2px',
                    }}
                  >
                    {option.label}
                  </Typography>
                  {option.newTag &&
                    getNewTag({
                      top: '6px',
                      right: '14px',
                    })}
                </Box>
              ))}
            </Box>
          </Paper>
        </Backdrop>
      </Box>
    );
  }

  useEffect(() => {
    eventBus.on(eventBusValues.triggerCloseBlocker, (data) => {
      setDrawerIsOnEditingMode(data);
    });
    eventBus.on(eventBusValues.onNewMessage, () => {
      setLocalUnreadMessages((prevValue) => prevValue + 1);
    });

    return () => {
      eventBus.remove(eventBusValues.triggerCloseBlocker);
      eventBus.remove(eventBusValues.onNewMessage);
    };
  }, []);

  return (
    <div style={classes.outerContainer}>
      <Paper sx={classes.container}>
        {isSM ? (
          <div style={classes.logoContainer}>
            <AdvisoryCloudLogo
              removeHyperlink
              onClick={() => {
                if (!drawerIsOnEditingMode) {
                  eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
                  if (location.pathname !== '/dashboard') {
                    history.push('/dashboard');
                  }
                } else {
                  editingAdvisorModalEvent();
                }
              }}
              width={153}
            />
          </div>
        ) : null}

        <div style={classes.navContainer}>
          <div style={classes.buttonsContainer}>
            <CustomMenuItem
              label={t('COMPANY-DASHBOARD-MENU-ITEM')}
              route="/dashboard"
              id="advisorDashboardNavBar"
            />
            <HoverMenuItem
              label="Advisory Boards"
              id="boardDirectoryNavBar"
              open={openAdvisoryBoards}
              setOpen={setOpenAdvisoryBoards}
              paths={['/board-directory']}
              options={[
                {
                  id: 'viewAdvisoryBoards',
                  label: 'View Advisory Boards',
                  route: '/board-directory',
                  onClick: () => {
                    setOpenAdvisoryBoards(false);
                    history.push('/board-directory');
                  },
                  mr: '10px',
                },
                {
                  id: 'buildYourOwn',
                  label: 'Build Your Own',
                  route: 'none',
                  onClick: () => {
                    setOpenAdvisoryBoards(false);
                    window.location.assign(
                      `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
                        { accountDetails, isFreeTrial, isFreemium }
                      )}`
                    );
                  },
                },
              ]}
            />
            <CustomMenuItem
              label={t('ADVISORS-BOARD-MEETINGS-MENU-ITEM')}
              route="/board-meetings"
              id="boardMeetingsNavBar"
            />
            <CustomMenuItem
              label="Network"
              id="networkingNavBar"
              route="/networking"
            />
            <CustomMenuItem
              label="Messages"
              route="/messages"
              messagesTag
              id="advisorMessagesNavBar"
            />
          </div>
          {bannerCompany && !isSM && !isWrapped && (
            <div style={classes.upcomingMeetingContainer}>
              <UpcomingMeetingBanner isSM={isSM} company={bannerCompany} />
            </div>
          )}
          <div style={classes.profileContainer}>
            <AdvisorAvatarMenu
              isSM={isSM}
              onAvatarMenuIconCLick={onAvatarMenuIconCLick}
              image={getAdvisorImagePath(accountDetails)}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default AdvisorUpperBar;
