export const AccountType = {
  COMPANY: 'company',
  ADVISOR: 'advisor',
  CONTACT: 'companyContact',
  ADMIN: 'admin',
};

export const PostType = {
  QUESTION: 'question',
  QUESTIONS: 'questions',
  UPDATE: 'update',
  UPDATES: 'updates',
  BOARD_MEETING: 'boardMeeting',
  BOARD_MEETINGS: 'boardMeetings',
  MEETING: 'meeting',
  MEETINGS: 'meetings',
  INSIGHT: 'insight',
  INSIGHTS: 'insights',
  ALL: 'all',
};

export const EndpointParameterFilters = {
  FETCH_LIMIT: 10,
  FETCH_LIMIT_PLUS: 30,
  COMPANIES_FETCH_LIMIT: 12,
  ORDER_BY_CREATED_AT: 'createdAt',
  ORDER_BY_SORT_AT: 'sortAt',
};

export const EndpointResponseObjectName = {
  GET_POSTS: 'getPosts',
  GET_BOARD_MEETING_POSTS: 'getBoardMeetings',
  GET_POST: 'getPost',
  GET_MY_ADVISORS: 'getMyAdvisors',
  GET_ADVISORS_TO_CONNECT: 'getAdvisorsToConnect',
  SEARCH_ADVISOR_BY_FILTERS: 'searchAdvisorByFilters',
  GET_RESPONSES_BY_ADVISOR: 'getResponsesByAdvisor',
  SEARCH_COMPANIES: 'searchCompanies',
};

export const NullCountry = {
  NULL_COUNTRY: '00',
};

export const AdvisorDetailCardMetric = {
  ANSWERS: 'answer',
  BREAKTHROUGHS: 'Breakthroughs',
  COMMENTS: 'comment',
};

export const LoadingBoards = {
  COMPLETE: 'COMPLETE',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
};

export const Enviroments = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test',
};

export const SnackbarType = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const InviteToJoinEndpointErrorMessages = {
  ERROR_INVITE_TO_JOIN_ONBOARDING_PROCESS_INCOMPLETE:
    "This company hasn't completed the on boarding process",
  ERROR_INVITE_TO_JOIN_BANNED: 'This advisor is banned from this board',
  ERROR_INVITE_TO_JOIN_BOARD_MEMBER:
    'This advisor is already a member in this board',
  ERROR_INVITE_TO_JOIN_INVITED: 'This advisor is already invited to this board',
  ERROR_INVITE_TO_JOIN_NO_QUESTION_POSTS:
    "The company can't invite advisors if there are no questions.",
  ERROR_INVITE_TO_JOIN_OPPORTUNITY_STAGE:
    "The legacy 'opportunity_stage shouldn't be 'discovery' or 'search_concluded'.",
  ERROR_INVITE_TO_JOIN_INVALID_URL: "The company doesn't have a valid url.",
  UnsuccessfullyInvitedToJoin: 'Error',
};

export const AdvisorSearchType = {
  MY_ADVISORS: 'MyAdvisors',
  ALL_ADVISORS: 'AllAdvisors',
};

export const AdvisorHeadlines = {
  INVITED: 'Invited',
  BOARD_MEMBER: 'Advisory Board Member',
};

export const OnboardingStatus = {
  CANCELED: `Canceled`,
  COMPLETE: `Complete`,
  MISSED: `Missed`,
  NOT_REQUISITE: `Not Requisite`,
  NOT_SCHEDULED: `Not Scheduled`,
  SCHEDULED: `Scheduled`,
};

export const OnboardingProcess = {
  IN_PROCESS: 'inProcess',
  COMPLETED: 'completed',
};

export const OpportunityStage = {
  SEARCH_CONCLUDED: 'search_concluded',
  DISCOVERY: 'discovery',
  SEEKING_ADVISORS: 'seeking_advisors',
};

export const ImageFormats = {
  ACCEPTED_FILE_TYPES: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
};

export const planCodeTerms = {
  MONTHLY: 'mo',
  QUARTERLY: 'qtr',
  YEARLY: 'yr',
};
