import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import { useAccount } from '../../contexts/Account';
import { advisorGetCompaniesPosts } from '../../services/company_services';
import {
  AccountType,
  PostType,
  EndpointParameterFilters,
  EndpointResponseObjectName,
} from '../../services/utils/types';
import DashboardAdvisorContent from '../../components/Advisor/DashboardContent/index';
import EndpointCaller from '../../components/EndpointCaller';
import AdvisorTopTiles from '../../components/TopTiles';
import Treatment from '../../components/Treatment';
import {
  CallTypes,
  useInfiniteScroll,
} from '../../components/Post/ResponseReply/useInfiniteScroll';
import {
  getIndexArrayValues,
  getValuesFromKeyInDataResponse,
  isNil,
} from '../../services/utils';
import { featureFlags } from '../../splitSetup';
import SearchBox from './searchBox';
import DashboardSkeleton from '../../components/SkeletonLoading/dashboardSkeleton';

const useStyles = ({ isSM, isFreeTrial, showBanner }) => ({
  screenContainer: {
    maxWidth: '1140px',
  },
  scrollscreenContainer: {
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: '20px',
    paddingRight: isSM ? '10px' : '30px',
    minHeight: isFreeTrial
      ? 'calc(100vh - 145px)'
      : showBanner
      ? 'calc(100vh - 125px)'
      : 'calc(100vh - 95px)',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    overflowX: 'scroll',
  },
});

function Dashboard(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    accountDetails,
    authenticate,
    isAuthenticated,
    isImpersonated,
    currentUserInfo,
    currentPlan,
    isFreeTrial,
  } = useAccount();
  const { salesforceValues } = currentPlan;
  const showBanner =
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const classes = useStyles({ isSM, isFreeTrial, showBanner });
  const [legacyIdErrorSnackbarVisible, setLegacyIdErrorSnackbarVisible] =
    useState(false);
  const [resignedSnackBarOpen, setResignedSnackBarOpen] = useState(false);
  const [filterBy, setFilterBy] = useState(['all']);
  const [sortBy, setSortBy] = useState('newest');
  const [loading, setLoading] = useState(true);
  const [advisorEndpointCalls, setAdvisorEndpointCalls] = useState([
    {
      endPointCall: advisorGetCompaniesPosts,
      type: CallTypes.MAIN_CALL,
      endPointCallParameters: {
        ACCOUNT_TYPE: AccountType.ADVISOR,
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: '',
        LIMIT: EndpointParameterFilters.FETCH_LIMIT,
        OFFSET: 0,
        ORDER_BY: getSort(),
        REVERSE: sortBy == 'oldest',
        TYPE: filterBy.join(','),
      },
      endPointAppSyncName: EndpointResponseObjectName.GET_POSTS,
    },
  ]);

  const {
    posts,
    isMainCallFetching,
    setInitialPosts,
    incrementOffset,
    addPostLike,
  } = useInfiniteScroll({ endpointCalls: advisorEndpointCalls });

  function GetEndpointResponseArray(array) {
    const post = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 0),
      'getPosts'
    );
    setInitialPosts(isNil(post) ? [] : post);
    setLoading(false);
  }

  function getSort() {
    if (sortBy == 'newest') {
      return 'sortAt';
    }
    if (sortBy == 'oldest') {
      return 'createdAt';
    }
    if (sortBy == 'recent-activity') {
      return 'updatedAt';
    }
    if (sortBy == 'mostPopular') {
      return 'mostPopular';
    }
  }

  useEffect(() => {
    async function fetchUserData() {
      await authenticate();
    }

    if (
      !isAuthenticated &&
      !isImpersonated &&
      props.location.state &&
      props.location.state.onboarded
    ) {
      fetchUserData();
    }

    if (location.state && location.state.missingLegacyId) {
      setLegacyIdErrorSnackbarVisible(true);
    }

    if (location.state && location.state.resignedCompany) {
      setResignedSnackBarOpen(true);
    }
  }, []);

  useEffect(() => {
    if (filterBy.length == 0) {
      return;
    }
    setLoading(true);
    setAdvisorEndpointCalls([
      {
        endPointCall: advisorGetCompaniesPosts,
        type: CallTypes.MAIN_CALL,
        endPointCallParameters: {
          ACCOUNT_TYPE: AccountType.ADVISOR,
          ADVISOR_ID: accountDetails.id,
          COMPANY_ID: '',
          LIMIT: EndpointParameterFilters.FETCH_LIMIT,
          OFFSET: 0,
          ORDER_BY: getSort(),
          REVERSE: sortBy == 'oldest',
          TYPE: filterBy.join(','),
        },
        endPointAppSyncName: EndpointResponseObjectName.GET_POSTS,
      },
    ]);
  }, [filterBy, sortBy]);

  function dasboardComponent(evaluated) {
    return (
      <DashboardAdvisorContent
        postsList={posts}
        noUrlChange
        isMainCallFetching={isMainCallFetching}
        incrementOffset={incrementOffset}
        addPostLike={addPostLike}
        publicProfileFlag={evaluated}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>
      <div style={classes.scrollscreenContainer}>
        <div style={classes.screenContainer}>
          <AdvisorTopTiles
            type="DASHBOARD"
            tileTitle={t('ABOUT-YOUR-DASHBOARD')}
            tileText="You'll start seeing updates, questions, and comments in your feed below for organizations you are advising for."
            cardId="aboutDashboardTile"
          />
          {/* HERE DASHBOARD TILES  */}
          {/* <SearchBox
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          sortBy={sortBy}
          setSortBy={setSortBy}
        /> */}

          <EndpointCaller
            endPointCallObjectArray={advisorEndpointCalls}
            onEndpointCallsFinished={GetEndpointResponseArray}
            executeCalls={loading}
            isDashboardSkeleton
          />
          {!loading ? (
            <Treatment
              userId={currentUserInfo.username}
              splitNames={featureFlags.publicProfilesCustomMetadata}
              updateOnSdkTimedout
              evaluatedComponent={dasboardComponent(true)}
              conditionFlag
              conditionFailedComponent={dasboardComponent(false)}
            />
          ) : (
            <DashboardSkeleton />
          )}
        </div>
      </div>

      <ACSnackbar
        open={legacyIdErrorSnackbarVisible}
        text={t('DASHBOARD-IWM-ID-NOT-PRESENT-ERROR')}
        severity="error"
        onClose={() => setLegacyIdErrorSnackbarVisible(false)}
        autoHideDuration={5000}
      />

      <ACSnackbar
        open={resignedSnackBarOpen}
        text={t('DASHBOARD-RESGINED-SNACKBAR', {
          displayName:
            location.state && location.state.resignedCompany
              ? location.state.resignedCompany
              : '',
        })}
        severity="success"
        onClose={() => {
          setResignedSnackBarOpen(false);
          window.history.replaceState({}, document.title);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}

export default Dashboard;
