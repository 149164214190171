import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Toolbar,
  Button,
  useMediaQuery,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useAccount } from './../../../contexts/Account';
import CompanyTiles from './../../TopTiles/company/CompanyTiles';
import Treatment from './../../Treatment';
import { featureFlags } from './../../../splitSetup';
import CompanyTopTilesContainer from './../../Company/TopTiles';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CustomIcon from './../../../icons/customIcon';
import eventBus, { eventBusValues } from './../../../eventBus';
import { ReactComponent as RoundTableIcon } from './roundtable.svg';

const useStyles = () => ({
  root: {
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.75,
    lineHeight: '32px',
    width: '100%',
    borderBottom: '1px solid #E6EDFF',
    // borderTop: '1px solid #E6EDFF',
    backgroundColor: '#fff',
    zIndex: '99',
  },
  outer: {
    height: '65px',
  },
  button: {
    width: '90px',
    marginRight: { xxs: '0px', sm: '25px' },
    textTransform: 'none',
    height: '40px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    color: 'var(--black, #232B35)',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  mainButton: {
    width: '205px',
    height: '48px',
    borderRadius: '25px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    textTransform: 'none',
    boxShadow: 'none',
  },
  mainButtonText: {
    width: '100px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
  },
});

function buttonUnderLine(text, path, history, location, id) {
  const isCurrentPath = location.pathname === path;

  return (
    <Box sx={{ position: 'relative', marginRight: { xxs: '0px', sm: '26px' } }}>
      <Button
        variant="text"
        disableRipple
        onClick={() => history.push(path)}
        sx={{
          textTransform: 'none',
          height: '40px',
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '32px',
          letterSpacing: '0.75px',
          color: 'var(--black, #232B35)',
          '&:hover': {
            backgroundColor: '#fff',
          },
          fontWeight: isCurrentPath ? 600 : 500,
        }}
        id={id}
      >
        {text}
      </Button>
      {isCurrentPath && (
        <Box
          sx={{
            position: 'absolute',
            bottom: -14,
            left: 0,
            right: 0,
            height: '2px',
            backgroundColor: '#646D7A',
          }}
        />
      )}
    </Box>
  );
}

const AdvisorsTopBar = ({ boxRef }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [sticky, setSticky] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { isFreeTrial, currentUserInfo, accountDetails } = useAccount();
  const navRef = useRef();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const buttonsData = [
    {
      text: `Your ${
        accountDetails.userSegment === 'managed_advisory_board'
          ? 'Advisory Board'
          : 'AdvisoryCloud'
      }`,
      path: '/advisors',
      id: 'b2bAdvisorsSubMenu',
    },
    {
      text: 'Search Network',
      path: '/advisors/search',
      id: 'b2bSearchAdvisorsSubMenu',
    },
    { text: 'Saved', path: '/advisors/saved', id: 'b2bSavedAdvisorsSubMenu' },
  ];
  const routeTextMap = {
    '/advisors': `Your ${
      accountDetails.userSegment === 'managed_advisory_board'
        ? 'Advisory Board'
        : 'AdvisoryCloud'
    }`,
    '/advisors/search': 'Search Network',
    '/advisors/saved': 'Saved',
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    switch (event.target.value) {
      case 'advisors':
        history.push('/advisors');
        break;
      case 'search':
        history.push('/advisors/search');
        break;
      case 'saved':
        history.push('/advisors/saved');
        break;
      default:
        break;
    }
  };

  function onScroll() {
    if (navRef && navRef.current && navRef.current.getBoundingClientRect) {
      setSticky(
        navRef.current.getBoundingClientRect().top < (isFreeTrial ? 130 : 80)
      );
    }
  }

  useEffect(() => {
    const path = location.pathname;
    if (path === '/advisors') {
      setSelectedOption('advisors');
    } else if (path === '/advisors/search') {
      setSelectedOption('search');
    } else if (path === '/advisors/saved') {
      setSelectedOption('saved');
    }
  }, [location]);

  useEffect(() => {
    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  return (
    <>
      <Box
        maxWidth="1260px"
        pr={isSM ? '10px' : '30px'}
        pl={isSM ? '10px' : '30px'}
        pt="30px"
      >
        {location.pathname === '/advisors' ? (
          <CompanyTopTilesContainer
            circleFirstColor="rgba(215, 241, 253, 1)"
            circleSecondColor="rgba(219, 247, 235, 1)"
            title={`Invite people to your ${
              accountDetails.userSegment === 'managed_advisory_board'
                ? 'advisory board'
                : 'AdvisoryCloud'
            }`}
            subtitle={`Invite your team, your contacts, and advisors from our network to join your ${
              accountDetails.userSegment === 'managed_advisory_board'
                ? 'advisory board'
                : 'AdvisoryCloud'
            }`}
            circleAlternative
            compactView
          >
            <div
              style={{
                display: 'flex',
                flexDirection: isSM ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: isSM ? '10px' : '20px',
                marginTop: isSM ? '20px' : '20px',
              }}
            >
              <Button
                onClick={() => history.push('/advisors/search')}
                id="searchNetworkButtonBanner"
                style={{
                  ...classes.mainButton,
                  background: 'rgba(49, 113, 246, 1)',
                }}
                variant="contained"
              >
                Search our network
              </Button>
              <Button
                id="inviteContactsButtonBanner"
                style={{
                  ...classes.mainButton,
                  border: 'solid 2px rgba(49, 113, 246, 1)',
                  marginLeft: isSM ? 0 : '15px',
                  marginTop: isSM ? '10px' : 0,
                }}
                variant="outlined"
                onClick={() =>
                  eventBus.dispatch(eventBusValues.triggerInviteContactsModal)
                }
              >
                Invite your contacts
              </Button>
            </div>
          </CompanyTopTilesContainer>
        ) : (
          <CompanyTopTilesContainer
            circleFirstColor="rgba(215, 241, 253, 1)"
            circleSecondColor="rgba(219, 247, 235, 1)"
            title="Tap into the network"
            subtitle="Leverage our network of senior professionals in a number of valuable formats"
            circleAlternative
            compactView
          >
            {!isSM ? (
              <div
                id="advisorsTopBarButtonsContainer"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {[
                  {
                    id: 'addToAdvisoryButtonBanner',
                    title: `Add to your ${
                      accountDetails.userSegment === 'managed_advisory_board'
                        ? 'advisory board'
                        : 'AdvisoryCloud'
                    }`,
                    icon: (
                      <div style={{ marginLeft: '-15px' }}>
                        <CustomIcon
                          iconname="invite-join"
                          sx={{
                            color: 'rgba(49, 113, 246, 1)',
                            fontSize: '21px',
                            marginBottom: '20px',
                          }}
                        />
                      </div>
                    ),
                  },
                  {
                    id: 'bookCallButtonBanner',
                    title: 'Book 1:1 video meetings',
                    icon: (
                      <CustomIcon
                        iconname="book-call"
                        sx={{
                          marginTop: '5px',
                          color: 'rgba(49, 113, 246, 1)',
                          fontSize: '24px',
                          marginBottom: '20px',
                        }}
                      />
                    ),
                  },
                  {
                    id: 'addToRoundtableButtonBanner',
                    title: 'Add to a roundtable',
                    icon: (
                      <RoundTableIcon
                        style={{
                          marginBottom: '10px',
                          padding: '0px',
                          marginTop: '10px',
                        }}
                      />
                    ),
                  },
                  {
                    id: 'hireForProjectButtonBanner',
                    title: 'Hire for a project',
                    icon: (
                      <CustomIcon
                        iconname="hire-advisor"
                        sx={{
                          marginTop: '5px',
                          color: 'rgba(49, 113, 246, 1)',
                          fontSize: '27px',
                          marginBottom: '15px',
                        }}
                      />
                    ),
                  },
                  {
                    id: 'hireForOpenRoleButtonBanner',
                    title: 'Hire for an open role',
                    icon: (
                      <PersonSearchIcon
                        sx={{
                          marginTop: '5px',
                          color: 'rgba(49, 113, 246, 1)',
                          fontSize: '31px',
                          marginBottom: '10px',
                        }}
                      />
                    ),
                  },
                ].map((item) => (
                  <div
                    id={item.id}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '139px',
                      height: '133px',
                      borderRadius: '10px',
                      margin: '10px',
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 4px 10px 0px rgba(230, 237, 254, 0.45)',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                        }}
                      >
                        {item.icon}
                      </div>
                    </div>
                    <Typography sx={classes.mainButtonText}>
                      {item.title}
                    </Typography>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ width: '100%', height: '40px' }}></div>
            )}
          </CompanyTopTilesContainer>
        )}
      </Box>
      <div
        style={{ display: 'flex', width: '1px', height: '0px' }}
        ref={navRef}
      />
      <Box
        sx={{
          ...classes.root,
          position: sticky ? 'fixed' : null,
          top: isSM ? '50px' : `${isFreeTrial ? '130px' : '80px'}`,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: { xxs: 'center', sm: 'flex-start' },
            paddingLeft: { xxs: '0px', sm: '20px' },
            paddingRight: { xxs: '0px' },
          }}
        >
          {!isSM &&
            buttonsData.map(({ text, path, id }) =>
              buttonUnderLine(text, path, history, location, id)
            )}
          {isSM && (
            <Select
              labelId="filter-label"
              id="filter-select"
              value={selectedOption}
              onChange={handleOptionChange}
              renderValue={() => routeTextMap[location.pathname] || ''}
              style={{ padding: '10px 5px 10px 5px' }}
              sx={{
                height: '40px',
                fontFamily: 'Poppins',
                fontSize: '14px',
                color: '#232B3',
                fontWeight: 400,
                padding: '5px',
                height: '44px',
                marginBottom: '10px',
                width: { xxs: '96%', xs: '98%' },
                borderRadius: '10px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #E6EDFF',
                },
              }}
            >
              <MenuItem value="advisors">Your AdvisoryCloud</MenuItem>
              <MenuItem value="search">Search Network</MenuItem>
              <MenuItem value="saved">Saved</MenuItem>
            </Select>
          )}
        </Toolbar>
      </Box>
      {sticky && <Box sx={classes.outer} />}
    </>
  );
};

export default AdvisorsTopBar;
