import { API, graphqlOperation } from 'aws-amplify';

export function getConversationMessages(params) {
  return API.graphql(
    graphqlOperation(
      `query getConversationMessages($member2: String, $member1: String, $conversationId: String) {
          getConversationMessages(
            member2: $member2, 
            member1: $member1, 
            conversationId: $conversationId
          ) {
            conversationId
            createdAt
            id
            message
            senderAccountType
            senderConversationMemberId
            senderId
            updatedAt
            profile {
              id
              image {
                imageKey
                originalImageKey
              }
              displayName
              contactLogoLocation
              companyId
            }
          }
        }
      `,
      params
    )
  );
}

export function createConversationMessage(params) {
  return API.graphql(
    graphqlOperation(
      `mutation createConversationMessage(
        $conversationId: String, 
        $senderConversationMemberId: String, 
        $accountType: String, 
        $senderId: String, 
        $receiver: String, 
        $message: String!) {
        createConversationMessage(
          conversationId: $conversationId, 
          senderConversationMemberId: $senderConversationMemberId, 
          accountType: $accountType, 
          senderId: $senderId, 
          receiver: $receiver, 
          message: $message) {
            id
            conversationId
            createdAt
            message
            senderAccountType
            senderConversationMemberId
            senderId
            updatedAt
            profile {
              id
              image {
                imageKey
                originalImageKey
              }
              displayName
              contactLogoLocation
              companyId
            }
          }
        }
      `,
      params
    )
  );
}

export function getConversations(params) {
  return API.graphql(
    graphqlOperation(
      `query getConversations(
        $id: String!
        $accountType: String!
      ) {
        getConversations
          (
            id: $id,
            accountType: $accountType
          ) {
            conversationId
            conversationMemberAccountId
            conversationMemberDisplayName
            conversationMemberPosition
            conversationMemberId
            conversationMemberImage
            lastMessage
            lastMessageDate
            lastMessageSender
            unreadCount
          }
        }
      `,
      params
    )
  );
}

export function getConversationViewItem(params) {
  return API.graphql(
    graphqlOperation(
      `query getConversationListView(
        $id: String!
        $accountType: String!
        $conversationId: String!
      ) {
        getConversationListView
          (
            id: $id,
            accountType: $accountType
            conversationId: $conversationId
          ) {
            conversationId
            conversationMemberAccountId
            conversationMemberDisplayName
            conversationMemberId
            conversationMemberImage
            lastMessage
            lastMessageDate
          }
        }
      `,
      params
    )
  );
}

export function getConversationMembers(params) {
  return API.graphql(
    graphqlOperation(
      `query getConversationMembers(
        $senderId: String
        $receiver: String
        $conversationId: String
      ) {
        getConversationMembers
          (
            senderId: $senderId,
            receiver: $receiver,
            conversationId: $conversationId
          ) {
            id
            accountType
            image {
              imageKey
              originalImageKey
            }
            displayName
            contactLogoLocation
            publicProfileUrl
            givenName
            surName
            salesforceContactId
            meetingRate
            companyName
            title
            experiences {
              position
              startDate
              endDate
              company
            }
            image {
              imageKey
              originalImageKey
            }
            plan {
              meetingsEnabled
              recurlyPlanCode
            }
          }
        }
      `,
      params
    )
  );
}

export function readMessages(params) {
  return API.graphql(
    graphqlOperation(
      `mutation ReadMessagesUpdate(
        $accountId: String!,
        $accountType: String!,
        $conversationId: String!
      ) {
        readMessagesUpdate(
          accountId: $accountId,
          accountType: $accountType,
          conversationId: $conversationId
        ) {
          id
        }
      }
      `,
      params
    )
  );
}

export function readSingleMessage(params) {
  return API.graphql(
    graphqlOperation(
      `mutation ReadMessagesUpdate(
        $accountId: String!,
        $accountType: String!,
        $conversationId: String!,
        $messageId: String
      ) {
        readMessagesUpdate(
          accountId: $accountId,
          accountType: $accountType,
          conversationId: $conversationId,
          messageId: $messageId
        ) {
          id
        }
      }
      `,
      params
    )
  );
}
