import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './textEditor.css';
import { findTextNewUrl } from './utils';
import { useAccount } from '../../contexts/Account';

function TextEditor({ initialText, setText, type, setWarning = () => {} }) {
  const editorReference = useRef();
  const { accountDetails } = useAccount();
  const { companyName } = accountDetails;

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [clearText, setClearText] = useState(initialText);
  const [placeholderText, setPlaceholderText] = useState('');

  useEffect(() => {
    if (type === 'question') {
      setPlaceholderText(t('POST-QUESTION-PLACEHOLDER'));
    }
    if (type === 'update') {
      setPlaceholderText(t('POST-UPDATE-PLACEHOLDER', { companyName }));
    }
    if (type === 'boardMeeting') {
      setPlaceholderText('Body');
    }
    if (editorReference && editorReference.current) {
      editorReference.current.focusEditor();
    }
  }, [type]);

  const onEditorStateChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    let text = contentState.getPlainText('');
    const fixedEditorState = findTextNewUrl(newEditorState, text, clearText);
    if (fixedEditorState) {
      setEditorState(fixedEditorState);
      saveContent();
    } else {
      setEditorState(newEditorState);
      saveContent();
    }
  };

  const saveContent = () => {
    const contentState = editorState.getCurrentContent();
    const raw = JSON.stringify(convertToRaw(contentState));
    let text = contentState.getPlainText('');
    setClearText(text);
    text = text.replace(/\s/g, '');
    text = text.replace(/\n/g, '');
    setWarning(text.length < 30);
    setText(raw);
  };

  const loadContent = () => {
    if (initialText) {
      try {
        const parsedContent = JSON.parse(initialText);
        const contentState = convertFromRaw(parsedContent);
        setEditorState(EditorState.createWithContent(contentState));
      } catch (e) {
        const contentState = ContentState.createFromText(initialText);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  };

  useEffect(() => {
    loadContent();
  }, [initialText]);

  useEffect(() => {
    if (editorReference && editorReference.current) {
      editorReference.current.focusEditor();
    }
  }, [editorReference]);

  return (
    <div>
      <Editor
        ref={editorReference}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        onContentStateChange={saveContent}
        placeholder={placeholderText}
        stripPastedStyles
        toolbar={{
          options: ['inline', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          link: {
            options: ['link'],
            defaultTargetOption: '_blank',
          },
        }}
        toolbarStyle={{
          border: 'none',
          paddingLeft: '0px',
          position: 'sticky',
          top: '0',
          zIndex: '1000',
          backgroundColor: 'white',
          width: isSM ? '100%' : '50%',
          marginBottom: isSM ? '50px' : '0px',
          marginLeft: isSM ? '-3px' : '-5px',
        }}
        editorStyle={{
          borderRadius: '5px',
          minHeight: '100px',
        }}
      />
    </div>
  );
}

export default TextEditor;
