import { useState, useEffect } from 'react';
import { getValuesFromKeyInDataResponse } from './../../../services/utils';
import {
  EndpointResponseObjectName,
  EndpointParameterFilters,
} from './../../../services/utils/types';

export const CallTypes = {
  MAIN_CALL: 'MAIN_CALL',
  ORDINARY_CALL: 'ORDINARY_CALL',
};

export const useInfiniteScroll = ({ endpointCalls, setLastFetch }) => {
  function checkIsMain(obj) {
    try {
      if (obj.type === CallTypes.MAIN_CALL) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  }

  function getMainEndpointCalls(obj) {
    return obj.filter((val) => checkIsMain(val));
  }

  function getMainCall(obj) {
    const mainCalls = getMainEndpointCalls(obj);
    if (mainCalls.length <= 0) return null;
    return mainCalls[0];
  }

  const [responseObject, setResponseObject] = useState(
    EndpointResponseObjectName.GET_POSTS
  );
  const [mainEndPointCall] = useState(getMainCall(endpointCalls));
  const LIMIT =
    mainEndPointCall.endPointCallParameters &&
    mainEndPointCall.endPointCallParameters.LIMIT
      ? mainEndPointCall.endPointCallParameters.LIMIT
      : EndpointParameterFilters.FETCH_LIMIT;
  const [offsetVal, setOffsetVal] = useState(LIMIT);
  const [mainEndPointCallParams] = useState(
    mainEndPointCall.endPointCallParameters
  );
  const [endPointAppSyncName] = useState(mainEndPointCall.endPointAppSyncName);
  const [isMainCallFetching, setIsMainCallFetching] = useState(false);
  const [returnOffset, setReturnOffset] = useState(false);
  const [errorOnMainCall, setErrorOnMainCall] = useState(false);
  const [posts, setPosts] = useState([]);
  const [addedManualPosts, setAddedManualPosts] = useState([]);
  const [noMorePosts, setNoMorePosts] = useState(false);
  // TODO verify if not asynchronous variables can be deleted
  // as they are recreated
  let nonAsynchrousIsMainCallFetching = false;
  let enfOfPosts = false;
  let previousOffSetCall = 0;

  function getNewEnpointParams(
    obj = mainEndPointCallParams,
    newOffset = offsetVal
  ) {
    const newObj = { ...obj };
    newObj.OFFSET = newOffset;
    return newObj;
  }

  function setInitialPosts(_posts, offset) {
    setIsMainCallFetching(false);
    nonAsynchrousIsMainCallFetching = false;
    enfOfPosts = false;
    previousOffSetCall = 0;
    setReturnOffset(offset ? offset : false);
    setNoMorePosts(false);
    setOffsetVal(LIMIT);
    setPosts(_posts);
  }

  function addInitialPosts(_post) {
    if (_post) {
      setPosts([_post, ...posts].flat());
      setAddedManualPosts([_post, ...addedManualPosts]);
    }
  }

  function incrementOffset() {
    if (
      !noMorePosts &&
      !enfOfPosts &&
      !isMainCallFetching &&
      !nonAsynchrousIsMainCallFetching
    ) {
      nonAsynchrousIsMainCallFetching = true;
      setOffsetVal((preState) => preState + LIMIT);
    }
  }

  function setDefaultResponseObject(nameOfResponseObject) {
    setResponseObject(nameOfResponseObject);
  }

  function getResponseArray(post) {
    if (EndpointResponseObjectName.GET_POSTS === endPointAppSyncName)
      return post;
    if (EndpointResponseObjectName.GET_MY_ADVISORS === endPointAppSyncName)
      return post;
    if (
      EndpointResponseObjectName.GET_BOARD_MEETING_POSTS === endPointAppSyncName
    )
      return post;
    if (
      EndpointResponseObjectName.SEARCH_ADVISOR_BY_FILTERS ===
      endPointAppSyncName
    )
      return post.advisors;
    if (EndpointResponseObjectName.SEARCH_COMPANIES === endPointAppSyncName)
      return post;
    if (
      EndpointResponseObjectName.GET_RESPONSES_BY_ADVISOR ===
      endPointAppSyncName
    )
      return post.posts;
    return [post];
  }

  function handleResponse(response) {
    const newData = getValuesFromKeyInDataResponse(response, responseObject);
    const arrayData = getResponseArray(newData);
    if (newData.offset) {
      setPosts((prevState) => [...prevState, ...arrayData].flat());

      if (arrayData.length === 0 || arrayData.length !== LIMIT) {
        enfOfPosts = true;
        setNoMorePosts(true);
      } else {
        setReturnOffset(newData.offset);
      }
    } else if (arrayData.length > 0) {
      setPosts((prevState) => [...prevState, ...arrayData].flat());
      // if (arrayData.length !== LIMIT) {
      if (arrayData.length === 0) {
        enfOfPosts = true;
        setNoMorePosts(true);
        setOffsetVal(
          (preState) => preState - ([...arrayData, ...posts].length + LIMIT)
        );
      }
    } else {
      enfOfPosts = true;
      setNoMorePosts(true);
      setOffsetVal((preState) => preState - LIMIT);
    }
  }

  function executeMainCall() {
    const offsetValue = returnOffset
      ? returnOffset
      : // need to subsctract the fetch limit as first we increment and later
        // fetch, we shoudl fetch and incremente late but we depend on the
        // offser use effect
        offsetVal -
        LIMIT +
        // this is an adjustment for posts manually added
        addedManualPosts.length;
    if (
      offsetValue > 0 &&
      previousOffSetCall < offsetValue &&
      !enfOfPosts &&
      !noMorePosts
    ) {
      setIsMainCallFetching(true);
      const endPointCallParams = getMainCall(endpointCalls);
      mainEndPointCall
        .endPointCall(
          getNewEnpointParams(
            endPointCallParams.endPointCallParameters,
            offsetValue
          )
        )
        .then((response) => {
          handleResponse(response);
          previousOffSetCall = offsetValue;
        })
        .catch(() => {
          setErrorOnMainCall(true);
        })
        .finally(() => {
          setIsMainCallFetching(false);
          nonAsynchrousIsMainCallFetching = false;
        });
    } else {
      nonAsynchrousIsMainCallFetching = false;
      setIsMainCallFetching(false);
    }
  }

  function editPost(postIndexReplace, newPost) {
    const newPosts = [
      ...posts.slice(0, postIndexReplace),
      newPost,
      ...posts.slice(postIndexReplace + 1),
    ];
    setPosts(newPosts);
  }

  function getPostBasedOnPostId(postId) {
    const resultPost = posts.filter((element) => element.id === postId);
    return resultPost.length > 0 ? resultPost[0] : null;
  }

  function isAlreadyLiked(postLikesArray, userId) {
    return (
      postLikesArray.filter((element) => element.userId === userId).length > 0
    );
  }

  function addPostLike(likeDTO) {
    let post = getPostBasedOnPostId(likeDTO.postId);

    if (post) {
      if (!isAlreadyLiked(likeDTO.postLikes, likeDTO.userId)) {
        post = {
          ...post,
          likesCount: post.likesCount + 1,
          likes: [
            ...post.likes,
            {
              accountType: likeDTO.type,
              name: likeDTO.name,
              userId: likeDTO.userId,
            },
          ],
        };
      } else {
        post = {
          ...post,
          likesCount: post.likesCount - 1,
          likes: post.likes.filter(
            (likeElement) => likeElement.userId !== likeDTO.userId
          ),
        };
      }
      editPost(likeDTO.index, post);
    }
  }

  useEffect(() => {
    if (setLastFetch) {
      setLastFetch(noMorePosts);
    }
  }, [noMorePosts]);

  useEffect(() => {
    executeMainCall();
  }, [offsetVal]);

  return {
    posts,
    offsetVal,
    isMainCallFetching,
    errorOnMainCall,
    executeMainCall,
    setInitialPosts,
    addInitialPosts,
    incrementOffset,
    setDefaultResponseObject,
    addPostLike,
    editPost,
  };
};
