import { API, graphqlOperation } from 'aws-amplify';

const advisorRecurlyPlanChangeSubscription = `
subscription onChangeRecurlyPlanCode($id: String!) {
    onChangeRecurlyPlanCode(id: $id) {
      id,
      accountStatus,
      recurlyPlanCode,
  }
}
`;

const companyBoardSeatsFilledChangeSubscription = `
subscription onChangeBoardSeatsFilledCount($id: String!) {
    onChangeBoardSeatsFilledCount(id: $id) {
      id,
      boardSeatsAllowed,
  }
}
`;

const companyBoardSeatsAllowedCountChangeSubscription = `
subscription onChangeBoardSeatsFilledCount($id: String!) {
  onChangeBoardSeatsFilledCount(id: $id) {
    id
    boardSeatsAllowed
  }
}
`;

const advisorFreeTrialEndsOnChangeSubscription = `
subscription onChangeFreeTrialEndsOn($id: String!) {
  onChangeFreeTrialEndsOn(id: $id) {
    id
    freeTrialEndsOn
  }
}
`;

const companyFreeTrialEndsOnChangeSubscription = `
subscription onChangeCompanyRecurlyPlanCode($id: String!) {
  onChangeCompanyRecurlyPlanCode(id: $id) {
    id
    freeTrialEndsOn
  }
}
`;

const onCompanyChangeOnboardingStatusSubscription = `
subscription onCompanyChangeOnboardingStatus($id: String!) {
  onCompanyChangeOnboardingStatus(id: $id) {
    onboardingStatus
  }
}`;

const onCompanyChangeBoardSeatasAvailable = `
subscription onCompanyChangeBoardSeatasAvailable($id: String!) {
  onCompanyChangeBoardSeatasAvailable(id: $id) {
      id,
      boardSeatsAvailable
      contacts {
        acceptTermsOfService
        accountStatus
        biography
        companyId
        contactS3Logo {
          location
          id
        }
        createdAt
        displayName
        email
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }
  }
}
`;

const onLikePost = `
subscription onLikePost {
  onLikePost {
    id
    companyId
    likes {
      accountType
      name
      userId
    }
    likesCount
  }
}
`;

const onLikeResponse = `
subscription onLikeResponse {
  onLikeResponse {
    id
    postCompanyId
    postId
    likesCount
    likes {
      accountType
      name
      userId
    }
  }
}
`;

const onLikeReply = `
subscription onLikeReply {
  onLikeReply {
    id
    postCompanyId
    postId
    likesCount
    likes {
      accountType
      name
      userId
    }
  }
}
`;

const onAddResponse = `
subscription onAddResponse($id: String!) {
  onAddResponse(postCompanyId: $id) {
    body
    breakthroughs {
      createdAt
      id
    }
    breakthroughsCount
    createdAt
    id
    likes {
      accountType
      name
      userId
    }
    likesCount
    postCompanyId
    postId
    repliesCount
    responseAccount {
      accountType
      companyName
      id
      imageURL
      name
      title
    }
    responseType
    updatedAt
    updatedByRecords {
      id
      updatedAt
    }
  }
}
`;

const onAddResponseOpen = `
subscription onAddResponse {
  onAddResponse {
    body
    breakthroughs {
      createdAt
      id
    }
    breakthroughsCount
    createdAt
    id
    likes {
      accountType
      name
      userId
    }
    likesCount
    postCompanyId
    postId
    repliesCount
    responseAccount {
      accountType
      companyName
      id
      imageURL
      name
      title
    }
    responseType
    updatedAt
    updatedByRecords {
      id
      updatedAt
    }
  }
}
`;

const onAddReply = `
subscription onAddReply($id: String!) {
  onAddReply(postCompanyId: $id) {
    body
    createdAt
    id
    likes {
      accountType
      name
      userId
    }
    likesCount
    postCompanyId
    postId
    replyAccount {
      accountType
      companyName
      id
      imageURL
      name
      title
    }
    responseId
    updatedAt
    updatedByRecords {
      id
      updatedAt
    }
  }
}
`;

const onAddReplyOpen = `
subscription onAddReply {
  onAddReply {
    body
    createdAt
    id
    likes {
      accountType
      name
      userId
    }
    likesCount
    postCompanyId
    postId
    replyAccount {
      accountType
      companyName
      id
      imageURL
      name
      title
    }
    responseId
    updatedAt
    updatedByRecords {
      id
      updatedAt
    }
  }
}
`;

const onNewMessage = `
subscription onNewMessage($conversationId: String!) {
  onNewMessage(conversationId: $conversationId) {
    conversationId
    createdAt
    id
    message
    senderAccountType
    senderConversationMemberId
    senderId
    updatedAt
    profile {
      image {
        imageKey
        originalImageKey
      }
      displayName
      contactLogoLocation
      companyId
    }
  }
}
`;

const onNewConversation = `
subscription onNewConversation($receiverId: String!) {
  onNewConversation(receiverId: $receiverId) {
    conversationId
    conversationMemberAccountId
    conversationMemberDisplayName
    conversationMemberId
    conversationMemberImage
    lastMessage
    lastMessageDate
    receiverId
    unreadCount
  }
}
`;

export function advisorRecurlyPLanSubscription({ id }) {
  return API.graphql(
    graphqlOperation(advisorRecurlyPlanChangeSubscription, { id })
  ).subscribe({
    next: () => {
      // eslint-disable-next-line no-console
      console.log('RecurlyPlanCodeChanged');
    },
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function companyBoardSeatsFilledSubscription({ id }) {
  return API.graphql(
    graphqlOperation(companyBoardSeatsFilledChangeSubscription, { id })
  ).subscribe({
    next: () => {
      // eslint-disable-next-line no-console
      console.log('CompanyBoardSeatsFilled change');
    },
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function companyBoardSeatsAllowedCountSubscription({ id }) {
  return API.graphql(
    graphqlOperation(companyBoardSeatsAllowedCountChangeSubscription, { id })
  ).subscribe({
    next: () => {
      // eslint-disable-next-line no-console
      console.log('CompanyBoardSeatsAllowed change');
    },
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function advisorFreeTrialEndsOnSubscription({ id }, cb) {
  return API.graphql(
    graphqlOperation(advisorFreeTrialEndsOnChangeSubscription, { id })
  ).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.log('ACD SUBSCRIPTION_ERROR:', error),
  });
}

export function companyFreeTrialEndsOnSubscription({ id }, cb) {
  return API.graphql(
    graphqlOperation(companyFreeTrialEndsOnChangeSubscription, { id })
  ).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.log('ACD SUBSCRIPTION_ERROR:', error),
  });
}

export function onCompanyChangeOnboardingStatus({ id, cb }) {
  return API.graphql(
    graphqlOperation(onCompanyChangeOnboardingStatusSubscription, { id, cb })
  ).subscribe({
    next: ({ value }) => {
      cb(value.data.onCompanyChangeOnboardingStatus.onboardingStatus);
    },
    // eslint-disable-next-line no-console
    error: (error) => console.log('ACD SUBSCRIPTION_ERROR:', error),
  });
}

export function companyChangeBoardSeatasAvailableSubscription({ id }, cb) {
  return API.graphql(
    graphqlOperation(onCompanyChangeBoardSeatasAvailable, { id })
  ).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function onLikePostSubscription(cb) {
  return API.graphql(graphqlOperation(onLikePost)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function onLikeResponseSubscription(cb) {
  return API.graphql(graphqlOperation(onLikeResponse)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function onLikeReplySubscription(cb) {
  return API.graphql(graphqlOperation(onLikeReply)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function companyOnAddResponseSubscription({ id }, cb) {
  return API.graphql(graphqlOperation(onAddResponse, { id })).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function companyOnAddReplySubscription({ id }, cb) {
  return API.graphql(graphqlOperation(onAddReply, { id })).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function advisorOnAddResponseSubscription(cb) {
  return API.graphql(graphqlOperation(onAddResponseOpen)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function advisorOnAddReplySubscription(cb) {
  return API.graphql(graphqlOperation(onAddReplyOpen)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function onNewMessageSubscription(params, cb) {
  return API.graphql(graphqlOperation(onNewMessage, params)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}

export function onNewConversationSubscription(params, cb) {
  return API.graphql(graphqlOperation(onNewConversation, params)).subscribe({
    next: cb,
    // eslint-disable-next-line no-console
    error: (error) => console.warn('SUBSCRIPTION_ERROR:', error),
  });
}
