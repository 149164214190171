import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import {
  useMediaQuery,
  Dialog,
  Typography,
  Button,
  CircularProgress,
  Box,
  TextField,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
import ACSnackbar from './../../ACSnackbar';
import { useAccount } from './../../../contexts/Account';
import { freemiumUpgradeAdvisor } from './../../../services/advisor_services';
import { SnackbarType } from './../../../services/utils/types';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import Shield from './shield.png';
import Team from './team-people.png';
import Stonks from './stonk.png';
import Company from './company.png';
import Arrow from './arrow.png';
import './styles.css';

const errorCodes = {
  validationError: 'VALIDATION_ERROR',
  recurlyError: 'RECURLY_ERROR',
  inAppError: 'IN_APP_ERROR',
  salesforceError: 'SALESFORCE_ERROR',
};

const Step2 = ({
  planData,
  onPlanChange,
  goBack,
  closeModal,
  onUpgradeSuccess,
}) => {
  const recurly = useRecurly();
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);
  const { accountDetails, currentUserInfo, isFreemium } = useAccount();
  const history = useHistory();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const { counterpartData, data, option, plan } = planData;
  const [selectedPlan, setSelectedPlan] = useState(plan === 'month' ? 1 : 2);
  const [upgradeOptions, setUpgradeOptions] = useState(data.recurlyPlanCode);
  const [isCardValid, setIsCardValid] = useState(false);
  const [model, setModel] = useState({
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    acceptTermsOfService: false,
  });

  const onInputChange = (event) => {
    const { name, type } = event.target;
    const value =
      type === 'checkbox' ? event.target.checked : event.target.value;
    setModel({ ...model, [name]: value });
  };

  function handleCardChange(event) {
    setIsCardValid(event.valid);
  }

  const countries = [
    { text: 'United States', value: 'US' },
    { text: 'Afghanistan', value: 'AF' },
    { text: 'Åland Islands', value: 'AX' },
    { text: 'Albania', value: 'AL' },
    { text: 'Algeria', value: 'DZ' },
    { text: 'American Samoa', value: 'AS' },
    { text: 'Andorra', value: 'AD' },
    { text: 'Angola', value: 'AO' },
    { text: 'Anguilla', value: 'AI' },
    { text: 'Antarctica', value: 'AQ' },
    { text: 'Antigua and Barbuda', value: 'AG' },
    { text: 'Argentina', value: 'AR' },
    { text: 'Armenia', value: 'AM' },
    { text: 'Aruba', value: 'AW' },
    { text: 'Australia', value: 'AU' },
    { text: 'Austria', value: 'AT' },
    { text: 'Azerbaijan', value: 'AZ' },
    { text: 'Bahamas', value: 'BS' },
    { text: 'Bahrain', value: 'BH' },
    { text: 'Bangladesh', value: 'BD' },
    { text: 'Barbados', value: 'BB' },
    { text: 'Belarus', value: 'BY' },
    { text: 'Belgium', value: 'BE' },
    { text: 'Belize', value: 'BZ' },
    { text: 'Benin', value: 'BJ' },
    { text: 'Bermuda', value: 'BM' },
    { text: 'Bhutan', value: 'BT' },
    { text: 'Bolivia', value: 'BO' },
    { text: 'Bosnia and Herzegovina', value: 'BA' },
    { text: 'Botswana', value: 'BW' },
    { text: 'Bouvet Island', value: 'BV' },
    { text: 'Brazil', value: 'BR' },
    { text: 'British Indian Ocean Territory', value: 'IO' },
    { text: 'Brunei Darussalam', value: 'BN' },
    { text: 'Bulgaria', value: 'BG' },
    { text: 'Burkina Faso', value: 'BF' },
    { text: 'Burundi', value: 'BI' },
    { text: 'Cambodia', value: 'KH' },
    { text: 'Cameroon', value: 'CM' },
    { text: 'Canada', value: 'CA' },
    { text: 'Cape Verde', value: 'CV' },
    { text: 'Cayman Islands', value: 'KY' },
    { text: 'Central African Republic', value: 'CF' },
    { text: 'Chad', value: 'TD' },
    { text: 'Chile', value: 'CL' },
    { text: 'China', value: 'CN' },
    { text: 'Christmas Island', value: 'CX' },
    { text: 'Cocos (Keeling) Islands', value: 'CC' },
    { text: 'Colombia', value: 'CO' },
    { text: 'Comoros', value: 'KM' },
    { text: 'Congo', value: 'CG' },
    { text: 'Congo, The Democratic Republic of the', value: 'CD' },
    { text: 'Cook Islands', value: 'CK' },
    { text: 'Costa Rica', value: 'CR' },
    { text: "Cote D'Ivoire", value: 'CI' },
    { text: 'Croatia', value: 'HR' },
    { text: 'Cuba', value: 'CU' },
    { text: 'Cyprus', value: 'CY' },
    { text: 'Czech Republic', value: 'CZ' },
    { text: 'Denmark', value: 'DK' },
    { text: 'Djibouti', value: 'DJ' },
    { text: 'Dominica', value: 'DM' },
    { text: 'Dominican Republic', value: 'DO' },
    { text: 'Ecuador', value: 'EC' },
    { text: 'Egypt', value: 'EG' },
    { text: 'El Salvador', value: 'SV' },
    { text: 'Equatorial Guinea', value: 'GQ' },
    { text: 'Eritrea', value: 'ER' },
    { text: 'Estonia', value: 'EE' },
    { text: 'Ethiopia', value: 'ET' },
    { text: 'Falkland Islands (Malvinas)', value: 'FK' },
    { text: 'Faroe Islands', value: 'FO' },
    { text: 'Fiji', value: 'FJ' },
    { text: 'Finland', value: 'FI' },
    { text: 'France', value: 'FR' },
    { text: 'French Guiana', value: 'GF' },
    { text: 'French Polynesia', value: 'PF' },
    { text: 'French Southern Territories', value: 'TF' },
    { text: 'Gabon', value: 'GA' },
    { text: 'Gambia', value: 'GM' },
    { text: 'Georgia', value: 'GE' },
    { text: 'Germany', value: 'DE' },
    { text: 'Ghana', value: 'GH' },
    { text: 'Gibraltar', value: 'GI' },
    { text: 'Greece', value: 'GR' },
    { text: 'Greenland', value: 'GL' },
    { text: 'Grenada', value: 'GD' },
    { text: 'Guadeloupe', value: 'GP' },
    { text: 'Guam', value: 'GU' },
    { text: 'Guatemala', value: 'GT' },
    { text: 'Guernsey', value: 'GG' },
    { text: 'Guinea', value: 'GN' },
    { text: 'Guinea-Bissau', value: 'GW' },
    { text: 'Guyana', value: 'GY' },
    { text: 'Haiti', value: 'HT' },
    { text: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { text: 'Holy See (Vatican City State)', value: 'VA' },
    { text: 'Honduras', value: 'HN' },
    { text: 'Hong Kong', value: 'HK' },
    { text: 'Hungary', value: 'HU' },
    { text: 'Iceland', value: 'IS' },
    { text: 'India', value: 'IN' },
    { text: 'Indonesia', value: 'ID' },
    { text: 'Iran, Islamic Republic Of', value: 'IR' },
    { text: 'Iraq', value: 'IQ' },
    { text: 'Ireland', value: 'IE' },
    { text: 'Isle of Man', value: 'IM' },
    { text: 'Israel', value: 'IL' },
    { text: 'Italy', value: 'IT' },
    { text: 'Jamaica', value: 'JM' },
    { text: 'Japan', value: 'JP' },
    { text: 'Jersey', value: 'JE' },
    { text: 'Jordan', value: 'JO' },
    { text: 'Kazakhstan', value: 'KZ' },
    { text: 'Kenya', value: 'KE' },
    { text: 'Kiribati', value: 'KI' },
    { text: "Korea, Democratic People'S Republic of", value: 'KP' },
    { text: 'Korea, Republic of', value: 'KR' },
    { text: 'Kuwait', value: 'KW' },
    { text: 'Kyrgyzstan', value: 'KG' },
    { text: "Lao People'S Democratic Republic", value: 'LA' },
    { text: 'Latvia', value: 'LV' },
    { text: 'Lebanon', value: 'LB' },
    { text: 'Lesotho', value: 'LS' },
    { text: 'Liberia', value: 'LR' },
    { text: 'Libyan Arab Jamahiriya', value: 'LY' },
    { text: 'Liechtenstein', value: 'LI' },
    { text: 'Lithuania', value: 'LT' },
    { text: 'Luxembourg', value: 'LU' },
    { text: 'Macao', value: 'MO' },
    { text: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { text: 'Madagascar', value: 'MG' },
    { text: 'Malawi', value: 'MW' },
    { text: 'Malaysia', value: 'MY' },
    { text: 'Maldives', value: 'MV' },
    { text: 'Mali', value: 'ML' },
    { text: 'Malta', value: 'MT' },
    { text: 'Marshall Islands', value: 'MH' },
    { text: 'Martinique', value: 'MQ' },
    { text: 'Mauritania', value: 'MR' },
    { text: 'Mauritius', value: 'MU' },
    { text: 'Mayotte', value: 'YT' },
    { text: 'Mexico', value: 'MX' },
    { text: 'Micronesia, Federated States of', value: 'FM' },
    { text: 'Moldova, Republic of', value: 'MD' },
    { text: 'Monaco', value: 'MC' },
    { text: 'Mongolia', value: 'MN' },
    { text: 'Montserrat', value: 'MS' },
    { text: 'Morocco', value: 'MA' },
    { text: 'Mozambique', value: 'MZ' },
    { text: 'Myanmar', value: 'MM' },
    { text: 'Namibia', value: 'NA' },
    { text: 'Nauru', value: 'NR' },
    { text: 'Nepal', value: 'NP' },
    { text: 'Netherlands', value: 'NL' },
    { text: 'Netherlands Antilles', value: 'AN' },
    { text: 'New Caledonia', value: 'NC' },
    { text: 'New Zealand', value: 'NZ' },
    { text: 'Nicaragua', value: 'NI' },
    { text: 'Niger', value: 'NE' },
    { text: 'Nigeria', value: 'NG' },
    { text: 'Niue', value: 'NU' },
    { text: 'Norfolk Island', value: 'NF' },
    { text: 'Northern Mariana Islands', value: 'MP' },
    { text: 'Norway', value: 'NO' },
    { text: 'Oman', value: 'OM' },
    { text: 'Pakistan', value: 'PK' },
    { text: 'Palau', value: 'PW' },
    { text: 'Palestinian Territory, Occupied', value: 'PS' },
    { text: 'Panama', value: 'PA' },
    { text: 'Papua New Guinea', value: 'PG' },
    { text: 'Paraguay', value: 'PY' },
    { text: 'Peru', value: 'PE' },
    { text: 'Philippines', value: 'PH' },
    { text: 'Pitcairn', value: 'PN' },
    { text: 'Poland', value: 'PL' },
    { text: 'Portugal', value: 'PT' },
    { text: 'Puerto Rico', value: 'PR' },
    { text: 'Qatar', value: 'QA' },
    { text: 'Reunion', value: 'RE' },
    { text: 'Romania', value: 'RO' },
    { text: 'Russian Federation', value: 'RU' },
    { text: 'RWANDA', value: 'RW' },
    { text: 'Saint Helena', value: 'SH' },
    { text: 'Saint Kitts and Nevis', value: 'KN' },
    { text: 'Saint Lucia', value: 'LC' },
    { text: 'Saint Pierre and Miquelon', value: 'PM' },
    { text: 'Saint Vincent and the Grenadines', value: 'VC' },
    { text: 'Samoa', value: 'WS' },
    { text: 'San Marino', value: 'SM' },
    { text: 'Sao Tome and Principe', value: 'ST' },
    { text: 'Saudi Arabia', value: 'SA' },
    { text: 'Senegal', value: 'SN' },
    { text: 'Serbia and Montenegro', value: 'CS' },
    { text: 'Seychelles', value: 'SC' },
    { text: 'Sierra Leone', value: 'SL' },
    { text: 'Singapore', value: 'SG' },
    { text: 'Slovakia', value: 'SK' },
    { text: 'Slovenia', value: 'SI' },
    { text: 'Solomon Islands', value: 'SB' },
    { text: 'Somalia', value: 'SO' },
    { text: 'South Africa', value: 'ZA' },
    { text: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { text: 'Spain', value: 'ES' },
    { text: 'Sri Lanka', value: 'LK' },
    { text: 'Sudan', value: 'SD' },
    { text: 'Suriname', value: 'SR' },
    { text: 'Svalbard and Jan Mayen', value: 'SJ' },
    { text: 'Swaziland', value: 'SZ' },
    { text: 'Sweden', value: 'SE' },
    { text: 'Switzerland', value: 'CH' },
    { text: 'Syrian Arab Republic', value: 'SY' },
    { text: 'Taiwan, Province of China', value: 'TW' },
    { text: 'Tajikistan', value: 'TJ' },
    { text: 'Tanzania, United Republic of', value: 'TZ' },
    { text: 'Thailand', value: 'TH' },
    { text: 'Timor-Leste', value: 'TL' },
    { text: 'Togo', value: 'TG' },
    { text: 'Tokelau', value: 'TK' },
    { text: 'Tonga', value: 'TO' },
    { text: 'Trinidad and Tobago', value: 'TT' },
    { text: 'Tunisia', value: 'TN' },
    { text: 'Turkey', value: 'TR' },
    { text: 'Turkmenistan', value: 'TM' },
    { text: 'Turks and Caicos Islands', value: 'TC' },
    { text: 'Tuvalu', value: 'TV' },
    { text: 'Uganda', value: 'UG' },
    { text: 'Ukraine', value: 'UA' },
    { text: 'United Arab Emirates', value: 'AE' },
    { text: 'United Kingdom', value: 'GB' },
    { text: 'United States Minor Outlying Islands', value: 'UM' },
    { text: 'Uruguay', value: 'UY' },
    { text: 'Uzbekistan', value: 'UZ' },
    { text: 'Vanuatu', value: 'VU' },
    { text: 'Venezuela', value: 'VE' },
    { text: 'Viet Nam', value: 'VN' },
    { text: 'Virgin Islands, British', value: 'VG' },
    { text: 'Virgin Islands, U.S.', value: 'VI' },
    { text: 'Wallis and Futuna', value: 'WF' },
    { text: 'Western Sahara', value: 'EH' },
    { text: 'Yemen', value: 'YE' },
    { text: 'Zambia', value: 'ZM' },
    { text: 'Zimbabwe', value: 'ZW' },
  ];

  function isFormComplete() {
    return (
      model.address &&
      model.city &&
      model.state &&
      model.postalCode &&
      model.country &&
      model.acceptTermsOfService
    );
  }

  function handleClose() {
    closeModal();
    setSelectedPlan(plan === 'month' ? 1 : 2);
  }

  function parseErrorMessage(errorMessage) {
    let [errorType, ...restOfMessage] = errorMessage.split(':');
    let message = restOfMessage.join(':').trim();
    return {
      type: errorType,
      message: message,
    };
  }

  async function upgradeCall() {
    if (selectedPlan && !loading) {
      setLoading(true);

      let recurlyForm = {
        first_name: accountDetails.givenName,
        last_name: accountDetails.surName,
        address1: model.address,
        city: model.city,
        state: model.state,
        postal_code: model.postalCode,
        country: model.country,
      };

      recurly.token(recurlyForm, (err, token) => {
        if (err) {
          setSnackMessage(
            'There was an error tokenizing the card. Please try again.'
          );
          setSnackbarError(true);
          return;
        } else {
          freemiumUpgradeAdvisor({
            advisorId: accountDetails.id,
            upgradePlanCode: upgradeOptions,
            recurlyBillingToken: token.id,
          })
            .then((response) => {
              if (response && response.data) {
                setSnackMessage(
                  'Success: Your account has been upgraded, you now have full access to AdvisoryCloud.'
                );
                const successMessage =
                  'Success: Your account has been upgraded, you now have full access to AdvisoryCloud.';
                onUpgradeSuccess(successMessage);
                setSnackbarSuccess(true);
                setLoading(false);
                setModel({
                  address: '',
                  city: '',
                  state: '',
                  postalCode: '',
                  country: 'US',
                  acceptTermsOfService: false,
                });
                setTimeout(() => {
                  handleClose();
                }, 3000);
              }
            })
            .catch((e) => {
              console.log('error', e);
              try {
                const err = JSON.parse(e.errors[0].message);
                if (err.code === errorCodes.recurlyError) {
                  let parsedError = parseErrorMessage(err.error);
                  setSnackMessage(parsedError.message);
                  setSnackbarError(true);
                } else if (err.code === errorCodes.validationError) {
                  let parsedError = parseErrorMessage(err.error);
                  setSnackMessage(parsedError.message);
                  setSnackbarError(true);
                } else if (err.code === errorCodes.salesforceError) {
                  let parsedError = parseErrorMessage(err.error);
                  setSnackMessage(parsedError.message);
                  setSnackbarSuccess(true);
                } else if (err.code === errorCodes.inAppError) {
                  setSnackMessage(
                    'There was an unexpected error in the application. Please try again later.'
                  );
                  setSnackbarSuccess(true);
                } else {
                  setSnackMessage(
                    'There was an unexpected error in the application. Please try again later.'
                  );
                  setSnackbarError(true);
                }
                setLoading(false);
              } catch (err) {
                setSnackMessage(
                  'There was an unexpected error in the application. Please try again later.'
                );
                setSnackbarError(true);
                setLoading(false);
              }
            });
        }
      });
    }
  }

  const handleSwitch = (type) => {
    if (type === 'month') {
      onPlanChange('month');
    } else {
      onPlanChange('annual');
    }
  };

  useEffect(() => {
    setUpgradeOptions(data.recurlyPlanCode);
  }, [planData]);

  return (
    <div>
      <Box
        id="freemiumUpgradeAdvisorModal"
        sx={{
          borderRadius: isSM ? '0px' : '15px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '15px',
            left: { xxs: '20px', sm: '70px' },
            top: { xxs: '20px', sm: '50px' },
            cursor: 'pointer',
          }}
        >
          <img
            onClick={goBack}
            src={Arrow}
            alt="arrow"
            style={{
              width: '16px',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: { xxs: '70px', sm: '40px' },
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              width: { xxs: '90%', sm: '100%' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                textAlign: 'center',
                fontWeight: 800,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#232B35',
                marginBottom: '5px',
              }}
            >
              Upgrade and unlock your full potential as an advisor
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: '14px',
                lineHeight: '24px',
                textAlign: 'center',
                fontWeight: 500,
                color: '#232B35',
              }}
            >
              Start your paid membership today to get access to all features and
              benefits
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xxs: 'column-reverse', sm: 'row' },
            padding: { xxs: '0px', sm: '0px 80px 0px 20px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: { xxs: '80%', xs: '60%', sm: '80%' },
                display: 'flex',
                flexDirection: 'column',
                padding: { xxs: '0px', sm: '0px 20px 0px 0px' },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 800,
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#232B35',
                  marginBottom: '10px',
                }}
              >
                {planData.option === 1 && 'Essentials'}
                {planData.option === 2 && 'Professional'}
                {planData.option === 3 && 'Executive'} Membership
              </Typography>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: isSM ? '10px' : '20px',
                  marginBottom: '10px',
                }}
              >
                <img
                  src={Company}
                  alt="Company"
                  style={{ marginRight: '20px' }}
                />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: '#232B35',
                  }}
                >
                  Unlock access to all resources and active discussions in
                  digital boardrooms you join
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: isSM ? '10px' : '20px',
                  marginBottom: '10px',
                }}
              >
                <img
                  src={Stonks}
                  alt="stonks"
                  style={{ marginRight: '20px' }}
                />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: '#232B35',
                  }}
                >
                  Boost your credentials by adding board roles to your LinkedIn
                  and professional materials
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: isSM ? '10px' : '20px',
                  marginBottom: '10px',
                }}
              >
                <img src={Team} alt="team" style={{ marginRight: '20px' }} />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: '#232B35',
                  }}
                >
                  Collaborate with fellow advisors and join live meetings with
                  company leadership
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: isSM ? '10px' : '20px',
                  marginBottom: '10px',
                }}
              >
                <img
                  src={Shield}
                  alt="shield"
                  style={{ marginRight: '20px' }}
                />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: '#232B35',
                  }}
                >
                  Secure your advisor profile and set yourself up to get hired
                  as an advisor in a variety of ways
                </Typography>
              </div>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: { xxs: '80%', xs: '60%', sm: '100%' },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* Buttons Options */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: { xxs: 1, xs: 0 },
                  marginBottom: '10px',
                }}
              >
                <div>
                  <button
                    disabled={loading}
                    type="button"
                    style={{
                      width: isXS ? '150px' : '160px',
                      height: '70px',
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      borderRadius: '15px',
                      outline: 'none',
                      border:
                        selectedPlan == 1
                          ? '3px #3171F6 solid'
                          : '1px #C7D0DE solid',
                      background: 'none',
                      '& :focus': {
                        outline: 'none',
                      },
                      position: 'relative',
                    }}
                    onClick={() => {
                      setSelectedPlan(1);
                      handleSwitch('month');
                    }}
                    id="currentPlanButton"
                  >
                    <div>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          fontWeight: 500,
                          color: '#232B35',
                          display: 'flex',
                        }}
                      >
                        Monthly
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 800,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#232B35',
                        }}
                      >
                        $
                        {data.recurlyPlanCodeTerm === 'mo'
                          ? data.recurlyPlanCodePrice
                          : counterpartData.recurlyPlanCodePrice}
                        <span
                          style={{
                            fontSize: '10px',
                            fontWeight: 400,
                            marginLeft: '5px',
                          }}
                        >
                          {' '}
                          per month
                        </span>
                      </Typography>
                    </div>
                  </button>
                  {selectedPlan == 1 && <Box sx={{ height: '20px' }}></Box>}
                </div>
                <div>
                  <button
                    disabled={loading}
                    type="button"
                    style={{
                      width: isXS ? '150px' : '160px',
                      height: '70px',
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      borderRadius: '15px',
                      outline: 'none',
                      border:
                        selectedPlan == 2
                          ? '3px #3171F6 solid'
                          : '1px #C7D0DE solid',
                      background: 'none',
                      '& :focus': {
                        outline: 'none',
                      },
                      position: 'relative',
                    }}
                    onClick={() => {
                      setSelectedPlan(2);
                      handleSwitch('annual');
                    }}
                    id="upgradePlanButton"
                  >
                    <div>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          fontWeight: 500,
                          color: '#232B35',
                          display: 'flex',
                        }}
                      >
                        Annual
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 800,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#232B35',
                        }}
                      >
                        $
                        {data.recurlyPlanCodeTerm === 'yr'
                          ? Math.ceil(Number(data.recurlyPlanCodePrice) / 12)
                          : Math.ceil(
                              Number(counterpartData.recurlyPlanCodePrice) / 12
                            )}
                        <span
                          style={{
                            fontSize: '10px',
                            fontWeight: 400,
                            marginLeft: '5px',
                          }}
                        >
                          {' '}
                          per month
                        </span>
                      </Typography>
                    </div>

                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 400,
                        background: '#6736FA',
                        borderRadius: '20px',
                        padding: '5px 11px',
                        color: 'white',
                        whiteSpace: 'nowrap',
                        position: 'absolute',
                        top: -15,
                        right: -15,
                      }}
                      style={{}}
                    >
                      Save{' '}
                      {data.recurlyPlanCodeTerm === 'yr'
                        ? Math.ceil(
                            (1 -
                              Number(data.recurlyPlanCodePrice) /
                                (Number(counterpartData.recurlyPlanCodePrice) *
                                  12)) *
                              100
                          )
                        : Math.ceil(
                            (1 -
                              Number(counterpartData.recurlyPlanCodePrice) /
                                (Number(data.recurlyPlanCodePrice) * 12)) *
                              100
                          )}
                      %
                    </Typography>
                  </button>
                  {selectedPlan == 2 && (
                    <>
                      <Typography
                        sx={{
                          color: '#3171F6',
                          fontSize: '10px',
                          marginTop: '5px',
                        }}
                      >
                        Billed annually at $
                        {data.recurlyPlanCodeTerm === 'yr'
                          ? Number(data.recurlyPlanCodePrice)
                          : Number(counterpartData.recurlyPlanCodePrice)}{' '}
                      </Typography>
                      {isXS && <Box sx={{ height: '15px' }} />}
                    </>
                  )}
                </div>
              </Box>

              {/* Recurly Form */}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Select
                  value={model.country}
                  name="country"
                  onChange={onInputChange}
                  sx={{ height: '45px' }}
                >
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country.value}>
                      {country.text}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  placeholder="Address"
                  name="address"
                  onChange={onInputChange}
                  sx={{
                    '& .MuiInputBase-root': { height: '45px' },
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <TextField
                    placeholder="City"
                    name="city"
                    onChange={onInputChange}
                    sx={{
                      '& .MuiInputBase-root': { height: '45px' },
                    }}
                  />
                  <TextField
                    placeholder="State"
                    name="state"
                    onChange={onInputChange}
                    sx={{
                      '& .MuiInputBase-root': { height: '45px' },
                    }}
                  />
                  <TextField
                    placeholder="Zip"
                    name="postalCode"
                    onChange={onInputChange}
                    sx={{
                      '& .MuiInputBase-root': { height: '45px' },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: '-10px',
                    marginBottom: { xxs: '10px', sm: '-10px' },
                  }}
                >
                  <CardElement onChange={handleCardChange} />
                </Box>

                {/* Terms and Conditions */}
                <Box
                  sx={{
                    width: '100%',
                    marginTop: isSM ? '-5px' : '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      name="acceptTermsOfService"
                      onChange={onInputChange}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 14 },
                        paddingLeft: 0,
                        paddingRight: '5px',
                      }}
                    />
                    <Typography style={{ fontSize: '11px' }}>
                      I have read and accept the AdvisoryCloud
                      <span style={{ color: '#0077ff' }}>
                        {' '}
                        <a
                          href="https://advisorycloud.com/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>
                      </span>
                    </Typography>
                  </div>
                  <Button
                    color="primary"
                    size="large"
                    variant={selectedPlan ? 'contained' : 'outlined'}
                    disabled={loading || !isFormComplete() || !isCardValid}
                    sx={{
                      borderWidth: '2px',
                      padding: '15px 50px 15px 50px',
                      textTransform: 'none',
                      height: '45px',
                      borderRadius: '40px',
                      borderColor: '#3171F6',
                      cursor: selectedPlan ? 'pointer' : 'not-allowed',

                      marginTop: { xxs: '10px', xs: '0px' },
                    }}
                    className="ftUpgradeButton"
                    onClick={upgradeCall}
                    id="freeTrialUpgradeButton"
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          marginRight: '14px',
                          marginLeft: '14px',
                          color: '#fff',
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{ fontWeight: 700, fontSize: '15px' }}
                        style={{}}
                      >
                        Upgrade
                      </Typography>
                    )}
                  </Button>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      marginBottom: isSM ? '5px' : '0px',
                      marginTop: '10px',
                      color: '#646D7A',
                      lineHeight: '18px',
                      fontWeight: 400,
                    }}
                  >
                    By clicking "Upgrade" your free plan will conclude and your
                    card will be charged.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => {
          setSnackbarSuccess(false);
        }}
        autoHideDuration={3000}
      />
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarError}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.ERROR}
        onClose={() => {
          setSnackbarError(false);
        }}
        autoHideDuration={6000}
      />
    </div>
  );
};

export default Step2;
