import React from 'react';
import Box from '@mui/material/Box';
import DefaultCompany from './boardEmptyState.svg';

const useStyles = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '76px',
    background: '#FBFCFD',
    border: '1px solid #F5F7FA',
    borderRadius: '12px',
    paddingLeft: '21px',
    cursor: 'pointer',
  },
  image: {
    width: '50px',
    height: '50px',
    marginRight: '16px',
    borderRadius: '8px',
    background: '#f5f7fa',
  },
  companyName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  pill: {
    position: 'absolute',
    top: '-8px',
    right: '-6px',
    height: '18px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '-0.32px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontFamily: 'Poppins',
  },
  invitationPill: {
    background: '#4BC6FF',
  },
  pendingPill: {
    background: '#1D95CD',
  },
});

const StatusPill = ({ status }) => {
  const classes = useStyles();

  if (!status || status === 'member') {
    return null;
  }

  return (
    <div
      style={{
        ...classes.pill,
        ...(status === 'invitation'
          ? classes.invitationPill
          : classes.pendingPill),
      }}
    >
      {status === 'invitation' ? 'Invitation' : 'Pending'}
    </div>
  );
};

const BoardCard = ({ companyLogo, companyName, status, onClick, slug }) => {
  const classes = useStyles();
  const trimmedCompanyName =
    companyName && companyName.length > 30
      ? companyName.substring(0, 30) + '...'
      : companyName;
  return (
    <Box
      className="dashboardPositionTile"
      sx={classes.container}
      onClick={onClick}
      id={slug}
    >
      {companyLogo && !companyLogo.includes('undefined') ? (
        <img
          src={`https://${companyLogo}`}
          alt="Company Logo"
          style={classes.image}
        />
      ) : (
        <img src={DefaultCompany} alt="Company Logo" style={classes.image} />
      )}
      <div style={classes.companyName}>{trimmedCompanyName}</div>
      <StatusPill status={status} />
    </Box>
  );
};

export default BoardCard;
