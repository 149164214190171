import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Typography } from '@mui/material';
import { ReactComponent as FreeTrial } from '../../icons/octicon_goal-24.svg';

const useStyles = (isSM) => ({
  container: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    zIndex: 1000,
    position: 'fixed',
  },
  fixedContainer: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    backgroundColor: '#232B35',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    color: 'white',
    height: '30px',
    width: '30px',
    marginRight: '15px',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 700,
  },
  hide: {
    display: 'none',
  },
});

function FreemiumBanner(props) {
  const { show } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const history = useHistory();

  function goToUpgradePage() {
    history.push('/upgrade/b2b_general');
  }

  return (
    <>
      <div style={show ? classes.container : classes.hide} id="freeTrialBanner">
        <button
          type="button"
          style={classes.fixedContainer}
          onClick={() => goToUpgradePage()}
        >
          {!isSM && <FreeTrial style={classes.icon} />}
          <Typography variant="h3" sx={classes.text}>
            You're on a free plan.&nbsp;
            <span style={{ textDecoration: 'underline' }}>
              Upgrade to unlock the full potential of AdvisoryCloud
            </span>
          </Typography>
        </button>
      </div>
    </>
  );
}
export default FreemiumBanner;
