import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

const useStyles = (isSM) => ({
  boardMeetingContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: isSM ? '0' : '10px',
    marginTop: isSM ? '10px' : '0',
  },
  badgeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  badgePast: {
    color: 'white',
    textAlign: 'center',
    background: '#2CA0A3',
    border: '2px solid #24A0A3',
    width: 'fit-content',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    borderRadius: isSM ? '15px' : '20px',
    padding: isSM ? '4px 11px' : '5px 15px',
    fontSize: isSM ? '12px' : '14px',
  },
  badgeLive: {
    backgroundColor: '#F92C2C',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    width: 'fit-content',
    borderRadius: isSM ? '15px' : '20px',
    padding: isSM ? '4px 11px' : '5px 15px',
    fontSize: isSM ? '12px' : '14px',
  },
  badgeFuture: {
    backgroundColor: '#6736FA',
    color: 'white',
    textAlign: 'center',
    width: 'fit-content',
    border: '2px solid #6736FA',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    borderRadius: isSM ? '15px' : '20px',
    padding: isSM ? '4px 11px' : '5px 15px',
    fontSize: isSM ? '12px' : '14px',
  },
});

function BoardMeetingIconBadge(props) {
  const { postData, setTimeLive, dashboardFormat } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function differenceInHours(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return diff;
  }

  const localeHour = new Date(postData.meetingTime);
  const today = new Date();

  const [time, setTime] = useState('');

  useEffect(() => {
    try {
      let strTime = localeHour.toLocaleString('en-US', {
        timeZone: `${postData.meetingTimeZone}`,
      });
      const postHour = new Date(strTime);

      setTime(
        postHour > today
          ? 'future'
          : differenceInHours(today, postHour) < 1
          ? 'live'
          : 'past'
      );

      if (postHour > today && differenceInHours(postHour, today) < 2) {
        const interval = setInterval(() => {
          const today = new Date();
          setTime(
            postHour > today
              ? 'future'
              : differenceInHours(today, postHour) < 1
              ? 'live'
              : 'past'
          );
        }, 60 * 1000);
        return () => {
          clearInterval(interval);
        };
      }
    } catch (error) {
      setTime('');
      console.log('Error in BoardMeetingIconBadge: ', error);
    }
  }, []);

  useEffect(() => {
    if (time == 'live') {
      setTimeLive(true);
    }
  }, [time]);

  return (
    <>
      <div style={classes.boardMeetingContainer}>
        <div style={classes.badgeContainer}>
          {time === 'live' ? <div style={classes.badgeLive}>Live</div> : null}
          {time === 'past' ? <div style={classes.badgePast}>Replay</div> : null}
          {time === 'future' ? (
            <div style={classes.badgeFuture}>Upcoming</div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default BoardMeetingIconBadge;
