import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, Typography } from '@mui/material';
import { useAccount } from './../../../contexts/Account';
import { ReactComponent as FreeTrial } from './../../../icons/octicon_goal-24.svg';
import eventBus, { eventBusValues } from './../../../eventBus';
import { AccountType } from './../../../services/utils/types';

const useStyles = (isSM, setFixedPosition) => ({
  container: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    zIndex: 99999,
    position: 'fixed',
    top: 0,
  },
  fixedContainer: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    backgroundColor: '#232B35',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: isSM ? '10px' : '50px',
    paddingRight: isSM ? '20px' : '70px',
    paddingTop: isSM ? '7px' : '0px',
    paddingBottom: isSM ? '7px' : '0px',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    color: 'white',
    height: '30px',
    width: '30px',
    marginRight: '15px',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 700,
  },
  hide: {
    display: 'none',
  },
});

function FreemiumAdvisorBanner(props) {
  const { show } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const { type } = useAccount();
  const setFixedPosition =
    type === AccountType.COMPANY && location.pathname === '/account';
  const classes = useStyles(isSM, setFixedPosition);

  function goToUpgradePage() {
    eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
  }

  return (
    <>
      <div style={classes.container} id="AdvisorFreemiumBanner">
        <button
          type="button"
          style={classes.fixedContainer}
          onClick={() => goToUpgradePage()}
        >
          {!isSM && <FreeTrial style={classes.icon} />}
          <Typography variant="h3" sx={classes.text}>
            You're currently on a free account! &nbsp;
            <span style={{ textDecoration: 'underline' }}>
              Click to unlock full access and formalize your role as a board
              member
            </span>
          </Typography>
        </button>
      </div>
    </>
  );
}

export default FreemiumAdvisorBanner;
