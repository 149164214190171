import React, { useState, useEffect } from 'react';
import { Link, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import ACSnackbar from './../../ACSnackbar';

import { useTranslation } from 'react-i18next';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import { DokaImageEditorModal } from 'react-doka';
import {
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_crop_defaults,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_decorate,
  plugin_decorate_defaults,
  plugin_decorate_locale_en_gb,
  component_shape_editor_locale_en_gb,
} from 'doka/doka';
import { useAccount } from './../../../contexts/Account';
import 'doka/doka.css';
import BuildingIcon from './../../../icons/building.svg';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginFileEncode,
  FilePondPluginImageValidateSize
);

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  ...plugin_crop_defaults,
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...plugin_decorate_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...component_shape_editor_locale_en_gb,
  },
};

const filePondCompanyOptions = {
  dropOnPage: 'true',
  stylePanelLayout: 'compact circle',
  stylePanelAspectRatio: '1:1',
  allowDrop: 'true',
  allowRemove: 'true',
  allowProcess: 'true',
  allowBrowse: 'true',
  allowFileSizeValidation: 'true',
  allowFileTypeValidation: 'true',
  allowImageExifOrientation: 'true',
  allowImagePreview: 'true',
  allowImageResize: 'true',
  allowImageTransform: 'true',
  allowImageCrop: 'true',
  allowFileEncode: 'true',
  allowImageValidateSize: 'true',
  imageTransformVariantsIncludeOriginal: 'true',
  imagePreviewMaxFileSize: '10MB',
  imageResizeMode: 'contain',
  maxFileSize: '9MB',
  imageResizeTargetWidth: '600',
  imageResizeTargetHeight: '600',
  imageCropAspectRatio: '1:1',
  maxFiles: 1,
  credits: 'false',
  acceptedFileTypes: `${['image/jpg', 'image/jpeg', 'image/png', 'image/gif']}`,
  imageTransformOutputMimeType: 'image/png',
  imageEditAllowEdit: 'true',
  styleImageEditButtonEditItemPosition: 'bottom right',
  instantUpload: 'false',
};

const useStyles = makeStyles((theme) => ({
  uploader: {
    width: '125px',
    height: '125px',
    '& .filepond--drop-label': {
      fontSize: '0.8rem',
      color: theme.palette.secondary.dark,
      backgroundColor: '#C7D0DE',
      cursor: 'pointer',
    },
    '& .filepond--drop-label label': {
      cursor: 'pointer',
    },
    '& .filepond--file': {
      fontSize: '0.8rem',
      cursor: 'pointer',
    },
    '& .filepond--file-info': {
      display: 'none',
      cursor: 'pointer',
    },
    cursor: 'pointer',
  },
  uploaderSlide: {
    width: '205px',
    height: '205px',
    '& .filepond--drop-label': {
      fontSize: '0.8rem',
      color: theme.palette.secondary.dark,
      backgroundColor: '#C7D0DE',
      cursor: 'pointer',
    },
    '& .filepond--drop-label label': {
      cursor: 'pointer',
    },
    '& .filepond--file': {
      fontSize: '0.8rem',
      cursor: 'pointer',
    },
    '& .filepond--file-info': {
      display: 'none',
      cursor: 'pointer',
    },
    cursor: 'pointer',
  },
}));

function CompanyLogoUploader(props) {
  const { url, status, resultFiles, disabled, uploaderText, isSlide } = props;
  const { t } = useTranslation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const { accountDetails } = useAccount();
  const [preloadedImage, setPreloadedImage] = useState();
  const [errorSnackbar, setErrorSnackbar] = useState('');
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [currentImage, setCurrentImage] = useState(url);
  const [dokaImage, setDokaImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const ref = React.useRef();
  async function preloadFilepondImage() {
    const imageToPreloadURL = await fetch(
      `${accountDetails.companyS3Logo.location}`,
      {
        method: 'get',
        headers: {
          'Access-Control-Origin': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .catch(() => {
        setShowErrorSnackbar(true);
        setErrorSnackbar(t('PROFILE-UPLOADER-ERROR'));
        setShowErrorSnackbar(true);
      });

    setPreloadedImage(imageToPreloadURL);
  }

  useEffect(() => {
    if (
      accountDetails.image &&
      accountDetails.image.location &&
      !accountDetails.image.location.includes('undefined')
    ) {
      preloadFilepondImage();
    }
  }, []);

  useEffect(() => {
    if (preloadedImage) {
      const newBlob = preloadedImage.slice(0, preloadedImage.size, 'image/png');
      const newBlobURL = URL.createObjectURL(newBlob);

      setCurrentImage(newBlobURL);
    }
  }, [preloadedImage]);

  function setupFiles(values) {
    if (values.length === 0) {
      resultFiles({
        originalImage: 'removed',
        image: null,
      });
      setCurrentImage(null);
      setDokaImage(null);
      setOpenModal(false);
    }
    if (values.length === 1) {
      setCurrentImage(values);
      setOpenModal(false);
    }
    return null;
  }

  function catchUpdate(data) {
    const result = URL.createObjectURL(data);
    setCurrentImage(result);
    setOpenModal(false);
  }

  function catchOutputFiles(data) {
    const outputFiles = ref.current.getFile();

    const base64Images = outputFiles.getFileEncodeBase64String();

    const originalImageBase64String = base64Images[0].data;
    const imageBase64String = base64Images[1].data;

    const originalImageType = data[0].file.type;
    const imageType = data[1].file.type;

    const originalImageBase64URL = `data:${originalImageType};base64,${originalImageBase64String}=`;
    const imageBase64URL = `data:${imageType};base64,${imageBase64String}=`;

    resultFiles({
      originalImage: originalImageBase64URL,
      image: imageBase64URL,
    });
  }

  const handleClick = (e) => {
    if (disabled) {
      e.stopPropagation();
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      id="companyLogoUploader"
    >
      <ACSnackbar
        open={showErrorSnackbar}
        text={errorSnackbar}
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FilePond
          ref={ref}
          // {...labels}
          {...filePondCompanyOptions}
          labelIdle={
            isSlide
              ? `<img src="${BuildingIcon}" width="140" />`
              : `<img src="${BuildingIcon}" width="80" />`
          }
          files={currentImage}
          className={isSlide ? classes.uploaderSlide : classes.uploader}
          onerror={(err) => {
            // eslint-disable-next-line no-console
            console.log('filepond', err);
            setErrorSnackbar(`${err.main}: ${err.sub}`);
            setShowErrorSnackbar(true);
            status(true);
          }}
          onupdatefiles={(files) => {
            setupFiles(files);
          }}
          onpreparefile={(files, output) => {
            const originalInput = URL.createObjectURL(output[0].file);
            setDokaImage(originalInput);
            catchOutputFiles(output);
          }}
          imageEditEditor={{
            open: () => {
              setOpenModal(true);
            },
          }}
        />
      </Box>
      {disabled ? (
        <Box
          sx={{
            backgroundColor: 'white',
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.5 : 1,
            height: '150px',
            width: '140px',
            marginTop: '-150px',
          }}
          onClick={handleClick}
        ></Box>
      ) : null}

      <div
        style={{
          marginTop: '-15px',
          paddingBottom: '5px',
          paddingTop: '10px',
          width: !isXS ? '170px' : '100%',
          textAlign: 'center',
          justifyContent: 'center',
          color: '#6e7682',
          cursor: disabled ? 'not-allowed' : 'pointer',
          fontWeight: '500',
          fontSize: '12px',
        }}
      >
        <Link
          style={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '24px',
            letterSpacing: '-0.43px',
            textAlign: 'center',
            color: '#646D7A',
            textDecoration: 'none',
          }}
          onClick={(x) => (!disabled ? ref.current.browse() : handleClick(x))}
        >
          {uploaderText || 'Add a logo'}
        </Link>
      </div>
      {openModal ? (
        <DokaImageEditorModal
          {...editorDefaults}
          src={dokaImage}
          onClose={() => setOpenModal(false)}
          onHide={() => setOpenModal(false)}
          onProcess={({ dest }) => catchUpdate(dest)}
          imageWriter={createDefaultImageWriter({
            mimeType: 'image/png',
          })}
        />
      ) : null}
    </div>
  );
}

export default CompanyLogoUploader;
